<script setup>
import { defineProps, ref, onMounted } from "vue";

const props = defineProps({
  context: Object,
});

let rows = ref([]);

function refreshRowUnits(event, rowIndex) {
  props.context.options.forEach((option) => {
    if (option.key === event.target.value) {
      rows.value[rowIndex].units = option.units;
      if (option.units.length) {
        rows.value[rowIndex].unitValue = option.units[0].key;
      } else {
        rows.value[rowIndex].unitValue = null;
      }
    }
  });
  setContextValue();
}

function addRow() {
  let row = {
    parameters: [],
    parameterValue: props.context.options[0].key,
    units: [],
    unitValue:
      props.context.options[0].units.length > 0
        ? props.context.options[0].units[0].key
        : null,
    value: null,
  };
  props.context.options.forEach((option) => {
    row.parameters.push({ key: option.key, name: option.name });
  });
  row.units = props.context.options[0].units;
  rows.value.push(row);
  setContextValue();
}

function removeRow(rowIndex) {
  rows.value.splice(rowIndex, 1);
  setContextValue();
}

function setContextValue() {
  let data = [];

  rows.value.forEach((row) => {
    data.push({
      parameter: row.parameterValue,
      unit: row.unitValue,
      value: row.value,
    });
  });

  props.context.node.input(data);
}

function populateRowData() {
  rows.value = [];
  if (props.context._value) {
    props.context._value.forEach((productParameter) => {
      var parameters = [];
      var units = [];
      props.context.options.forEach((option) => {
        parameters.push({ key: option.key, name: option.name });
        if (option.key === productParameter.parameter["@id"]) {
          units = option.units;
        }
      });
      // var units = [];
      rows.value.push({
        parameters: parameters,
        parameterValue: productParameter.parameter["@id"],
        units: units,
        unitValue: productParameter.unit ? productParameter.unit["@id"] : null,
        value: productParameter.value,
      });
    });
  }
  setContextValue();
}

onMounted(() => {
  populateRowData();
});
</script>

<template>
  <table class="table align-middle">
    <tbody>
      <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
        <td>
          <select
            @change="refreshRowUnits($event, rowIndex)"
            v-model="row.parameterValue"
            class="form-control"
          >
            <option
              v-for="parameter in row.parameters"
              :value="parameter.key"
              :key="parameter.key"
            >
              {{ parameter.name }}
            </option>
          </select>
        </td>
        <td>
          <input
            type="text"
            v-model="row.value"
            class="form-control"
            @input="setContextValue"
          />
        </td>
        <td>
          <select
            v-if="row.units.length"
            v-model="row.unitValue"
            class="form-control"
            @change="setContextValue"
          >
            <option v-for="unit in row.units" :value="unit.key" :key="unit.key">
              {{ unit.name }}
            </option>
          </select>
        </td>
        <td>
          <button
            class="btn btn-danger btn-sm"
            @click.prevent="removeRow(rowIndex)"
          >
            Remove
          </button>
        </td>
      </tr>
      <tr>
        <td>
          <button class="btn btn-primary btn-sm" @click.prevent="addRow">
            Add
          </button>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
td {
  min-width: 80px;
}
</style>
