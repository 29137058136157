<template>
  <Main>
    <h1>Edit store</h1>
    <div class="row">
      <div class="col-lg-8">
        <Spinner v-if="loading"></Spinner>
        <FormKit v-else type="form" @submit="onSubmit">
          <FormKitSchema :schema="schema" :data="data" />
        </FormKit>
      </div>
    </div>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      loading: true,
      fields: fields,
      data: {},
    };
  },
  computed: {
    schema() {
      return forms.createFormKitSchema(fields);
    },
  },
  methods: {
    async onSubmit(formData) {
      await api.store.update(this.$route.params.id, formData);
      this.$router.push({ name: "SuperAdminStoreList" });
    },
    async fetchData() {
      this.loading = true;
      var data = await api.store.get(this.$route.params.id);
      this.fields.forEach(
        (column) => (this.data[column.key] = data[column.key])
      );
      this.loading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
  components: { Main, Spinner, FormKit, FormKitSchema },
};
</script>
