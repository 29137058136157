import { createApp } from "vue";
import OrderStatusBadge from "@/components/widgets/OrderStatusBadge";
import moment from "moment";
const baseUrl = "https://back.ginex.eu";
const productImageThumbnailPath = "/images/product/thumbnails";

let productImageThumbnailUrl = (src) =>
  baseUrl + productImageThumbnailPath + "/" + src;

export default {
  date: (value) => moment(value).format("DD.MM.YYYY hh:mm"),
  joinItems: (items) => items.map((item) => item.name).join(", "),
  name: (item) => (item ? item.name : ""),
  productImageThumbnailUrl: productImageThumbnailUrl,
  productImageList: (src) => {
    if (src) {
      return (
        '<img src="' +
        productImageThumbnailUrl(src) +
        '" style="height: 50px;"/>'
      );
    }
  },
  productImage: (src) => {
    if (src) {
      return (
        '<img src="' +
        productImageThumbnailUrl(src) +
        '" style="width: 100px;"/>'
      );
    }
  },
  productParameters: (productParameters) => {
    let result = '<table class="table"><tbody>';
    productParameters.forEach((productParameter) => {
      let unit = "";
      if (productParameter.unit) {
        unit = " " + productParameter.unit.name;
      }
      result +=
        "<tr><td>" +
        productParameter.parameter.name +
        "</td><td>" +
        productParameter.value +
        unit +
        "</td></tr>";
    });
    result += "</tbody></table>";
    return result;
  },
  orderStatusBadge: (status) => {
    let statusName = "NEW";
    let className = "badge align-middle fw-bold fs-6";
    switch (status) {
      case "new":
        className += " bg-info";
        break;
      case "complete":
        statusName = "COMPLETE";
        className += " bg-success";
        break;
      case "complete_partial":
        statusName = "PARTIAL";
        className += " bg-warning";
        break;
    }
    let output = '<span class="' + className + '">' + statusName + "</span>";
    return output;
  },
  price: (value) => parseFloat(value).toFixed(2) + "€",
};
