import format from "@/services/format";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Units",
    key: "units",
    formKit: {
      props: {
        label: "Units",
        type: "checkbox",
        options: {},
      },
    },
    formatter: (value) => format.joinItems(value),
  },
];
