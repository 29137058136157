<template>
  <Main>
    <h1>Edit parameter</h1>
    <div class="row">
      <div class="col-lg-8">
        <Spinner v-if="loading"></Spinner>
        <FormKit v-else type="form" @submit="onSubmit">
          <FormKitSchema :schema="schema" :data="data" />
        </FormKit>
      </div>
    </div>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      loading: true,
      fields: fields,
      schema: {},
      data: {},
    };
  },
  methods: {
    async onSubmit(formData) {
      await api.parameter.update(this.$route.params.id, formData);
      this.$router.push({ name: "SuperAdminParameterList" });
    },
    async fetchData() {
      this.loading = true;
      var units = await api.unit.getAll({ pagination: false });
      this.fields.forEach((field, index, object) => {
        if (field.key == "units") {
          if (units.items.length) {
            field.formKit.props.options = forms.mapOptions(units.items);
          } else {
            object.splice(index, 1);
          }
        }
      });
      var data = await api.parameter.get(this.$route.params.id);
      this.fields.forEach((column) => {
        if (column.key == "units") {
          this.data[column.key] = data[column.key].map(
            (subItem) => subItem["@id"]
          );
        } else {
          this.data[column.key] = data[column.key];
        }
      });
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchData();
    this.schema = forms.createFormKitSchema(fields);
  },
  components: { Main, Spinner, FormKit, FormKitSchema },
};
</script>
