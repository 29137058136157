<template>
  <Main>
    <h1>Edit access list</h1>
    <Spinner v-if="loading"></Spinner>
    <div v-else class="row">
      <div class="col-lg-6 col-md-8">
        <form @submit.prevent="onSubmit">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Enter name"
              v-model="name"
              required
            />
          </div>
          <div class="mb-3">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </Main>
</template>

<script>
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";

export default {
  components: {
    Main,
    Spinner,
  },
  data() {
    return {
      loading: true,
      name: "",
      accessList: null,
    };
  },
  methods: {
    async onSubmit() {
      await api.accessList.update(this.$route.params.id, { name: this.name });
      await this.$router.push({ name: "CompanyAdminAccessListList" });
    },
    async fetchData() {
      this.loading = true;
      this.accessList = await api.accessList.get(this.$route.params.id);
      this.name = this.accessList.name;
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
