<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import api from "@/services/api";
import MainLayout from "@/layouts/MainLayout";

const store = useStore();
const router = useRouter();
let sites = ref(api.getEmptyCollection());

async function fetchData() {
  sites.value = await api.site.getAll();
}
async function selectSite(siteId) {
  await store.dispatch("setSiteId", { siteId: parseInt(siteId) });
  await router.push({ name: "SiteLeaderProductList" });
}

onMounted(async () => {
  await fetchData();
});
</script>

<template>
  <MainLayout>
    <h1>Select site</h1>
    <br />
    <div id="site-selector" class="list-group">
      <a
        v-for="site in sites.items"
        :key="site.id"
        @click.prevent="selectSite(site.id)"
        href="#"
        class="list-group-item list-group-item-action"
        >{{ site.name }}</a
      >
    </div>
  </MainLayout>
</template>

<style scoped>
#site-selector {
  max-width: 500px;
}
</style>
