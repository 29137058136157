export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Parent",
    key: "parent",
    sortKey: "parent.name",
    hasListView: true,
    formKit: {
      props: {
        type: "select",
        options: [],
      },
    },
    formatter: (parent) => (parent ? parent.name : "No parent"),
  },
];
