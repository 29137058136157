import moment from "moment";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Email",
    key: "email",
    hasListView: true,
    formKit: {
      props: {
        type: "email",
        validation: "required",
      },
    },
  },
  {
    name: "Password",
    key: "password",
    formKit: {
      props: {
        type: "password",
      },
    },
  },
  {
    name: "Role",
    key: "role",
    hasListView: true,
    formatter: function (value) {
      switch (value) {
        case "ROLE_SUPER_ADMIN":
          return "Super admin";
        case "ROLE_COMPANY_ADMIN":
          return "Company admin";
        case "ROLE_SITE_LEADER":
          return "Site leader";
        case "ROLE_SUPPLIER":
          return "Supplier";
      }
    },
    formKit: {
      props: {
        type: "select",
        placeholder: "Select role",
        options: {
          ROLE_SUPER_ADMIN: "Super admin",
          ROLE_COMPANY_ADMIN: "Company admin",
          ROLE_SITE_LEADER: "Site leader",
          ROLE_SUPPLIER: "Supplier",
        },
        validation: "required",
      },
    },
  },
  {
    name: "Company",
    key: "company",
    hasListView: true,
    sortKey: "company.name",
    formatter: (company) => company.name,
    formKit: {
      props: {
        type: "select",
        options: [],
        placeholder: "Select company",
        disabled: {
          if: "$get(role).value == ROLE_SUPER_ADMIN",
          then: true,
        },
      },
    },
  },
  {
    name: "Firstname",
    key: "firstname",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Lastname",
    key: "lastname",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "ID code",
    key: "idCode",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Phone",
    key: "phone",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Created at",
    key: "createdAt",
    formatter: function (value) {
      var date = moment(value);

      return date.format("DD.MM.YYYY hh:mm");
    },
  },
  {
    name: "Updated at",
    key: "updatedAt",
    formatter: function (value) {
      var date = moment(value);

      return date.format("DD.MM.YYYY hh:mm");
    },
  },
];
