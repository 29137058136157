<template>
  <Main
    @siteSelected="onSiteSelected"
    @accessListSelected="onAccessListSelected"
  >
    <ProductList
      :selected-products-ids="selectedProductsIds"
      @products-selection-changed="onProductsSelectionChanged"
      @product-added="onProductAdded"
      @product-removed="onProductRemoved"
      ref="productList"
    ></ProductList>
  </Main>
</template>

<script>
import api from "@/services/api";
import Main from "@/layouts/MainLayout.vue";
import format from "@/services/format";
import ProductList from "@/components/lists/ProductList";

export default {
  components: {
    Main,
    ProductList,
  },
  data() {
    return {
      format: format,
      selectedProductsIds: [],
    };
  },
  methods: {
    async getSelectedProducts() {
      this.selectedProductsIds = this.$store.state.cartItems.items.map(
        (item) => item.product.id
      );
    },
    async onProductsSelectionChanged(selectedProductsIds) {
      this.selectedProductsIds = selectedProductsIds;
    },
    async onProductAdded(productId) {
      if (this.$store.state.siteId) {
        await api.cartItem.create(
          {
            product: "/api/products/" + productId,
            quantity: 1,
          },
          this.$store.state.siteId
        );
        await this.$store.dispatch("fetchCartItems");
      }
    },
    async onProductRemoved(productId) {
      for (const cartItem of this.$store.state.cartItems.items) {
        if (cartItem.product.id === productId) {
          await api.cartItem.delete(cartItem.id);
          await this.$store.dispatch("fetchCartItems");
        }
      }
    },
    async onSiteSelected() {
      await this.getSelectedProducts();
    },
    async onAccessListSelected() {
      await this.$refs.productList.fetchProducts();
    },
  },
  async mounted() {
    await this.getSelectedProducts();
  },
};
</script>
