import moment from "moment";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Country",
    key: "country",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
      },
    },
  },
  {
    name: "City",
    key: "city",
    formKit: {
      props: {
        type: "text",
      },
    },
  },
  {
    name: "Address",
    key: "address",
    formKit: {
      props: {
        type: "text",
      },
    },
  },
  {
    name: "Phone",
    key: "phone",
    formKit: {
      props: {
        type: "tel",
      },
    },
  },
  {
    name: "Email",
    key: "email",
    formKit: {
      props: {
        type: "email",
      },
    },
  },
  {
    name: "Type",
    key: "type",
    hasListView: true,
    formKit: {
      props: {
        type: "radio",
        options: [
          { label: "Producer", value: "producer" },
          { label: "Reseller", value: "reseller" },
        ],
      },
    },
  },
  {
    name: "Contact person",
    key: "contactPersonName",
    formKit: {
      props: {
        type: "text",
      },
    },
  },
];
