<template>
  <Main></Main>
</template>

<script>
// @ is an alias to /src
import Main from "@/layouts/MainLayout.vue";

export default {
  name: "Home",
  components: {
    Main,
  },
};
</script>
