<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Main from "@/layouts/MainLayout";
import Spinner from "@/components/widgets/SpinnerWidget";
import api from "@/services/api";
import format from "@/services/format";
import math from "@/services/math";

const store = useStore();
const router = useRouter();
const loading = ref(true);
const cartTotal = computed(() =>
  math.round(
    store.state.cartItems.items
      .map((cartItem) => cartItem.product.price * cartItem.quantity)
      .reduce((partialSum, a) => partialSum + a, 0),
    2
  )
);

async function fetchData() {
  loading.value = true;
  await store.dispatch("fetchCartItems");
  loading.value = false;
}

async function removeItem(id) {
  await api.cartItem.delete(id);
  await store.commit("removeCartItem", id);
}

async function updateQuantity(id, event) {
  let quantity = parseInt(event.target.value);
  if (quantity > 0) {
    await api.cartItem.patch(id, { quantity: quantity });
    await store.commit("updateCartItemQuantity", {
      id: id,
      quantity: quantity,
    });
  }
}

async function placeOrder() {
  let response = await api.order.orderCreateFromCart(store.state.siteId);
  await store.dispatch("fetchCartItems");
  await router.push({
    name: "SiteLeaderOrderView",
    params: { id: response.data.id },
  });
}

onMounted(async () => {
  await fetchData();
});
</script>

<template>
  <Main>
    <h1>Cart</h1>
    <br />

    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <div id="cart">
        <div v-if="$store.state.cartItems.totalItems > 0">
          <div class="table-wrapper">
            <table class="table table-hover align-middle">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Total</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in $store.state.cartItems.items"
                  :key="item['@id']"
                >
                  <td>
                    <span
                      v-html="format.productImageList(item.product.image)"
                    ></span>
                  </td>
                  <td>{{ item.product.name }}</td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      :value="item.quantity"
                      class="quantity form-control"
                      @input="updateQuantity(item.id, $event)"
                    />
                  </td>
                  <td>{{ item.product.price }}</td>
                  <td>
                    {{ math.round(item.product.price * item.quantity, 2) }}
                  </td>
                  <td>
                    <a
                      class="btn btn-sm btn-danger"
                      @click="removeItem(item.id)"
                      ><i class="bi bi-trash"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="fw-bold">{{ cartTotal }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-3">
            <button class="btn btn-primary" @click="placeOrder">
              Place order
            </button>
          </div>
        </div>
        <div v-else>Cart is empty</div>
      </div>
    </div>
  </Main>
</template>

<style scoped>
.table-wrapper {
  overflow-x: auto;
}

input.quantity {
  width: 60px;
}
</style>
