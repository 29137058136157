import format from "@/services/format.js";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Image",
    key: "image",
    hasListView: true,
    formKit: {
      props: {
        type: "file",
      },
    },
    formatter: (value) => format.productImage(value),
    listFormatter: (value) => format.productImageList(value),
  },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Code",
    key: "code",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Category",
    key: "category",
    hasListView: true,
    sortKey: "category.name",
    formKit: {
      props: {
        type: "select",
        placeholder: "Select category",
        options: {},
      },
    },
    formatter: (value) => format.name(value),
  },
  {
    name: "Manufacturer",
    key: "manufacturer",
    hasListView: true,
    sortKey: "manufacturer.name",
    formKit: {
      props: {
        type: "select",
        placeholder: "Select manufacturer",
        options: {},
      },
    },
    formatter: (value) => format.name(value),
  },
  {
    name: "Price",
    key: "price",
    hasListView: true,
    formKit: {
      props: {
        type: "number",
        step: 0.01,
        validation: "required",
      },
    },
  },
  {
    name: "Quantity",
    key: "quantity",
    hasListView: true,
    formKit: {
      props: {
        type: "number",
        step: 1,
        validation: "required",
      },
    },
  },
  {
    name: "Parameters",
    key: "productParameters",
    formKit: {
      props: {
        type: "productParameters",
        options: [],
      },
    },
    formatter: (value) => format.productParameters(value),
  },
];
