<template>
  <Main>
    <h1 class="mb-4">View supplier</h1>
    <div class="row">
      <div class="col-lg-8">
        <Spinner v-if="loading"></Spinner>
        <ViewTable :columns="fields" :data="data" v-else></ViewTable>
      </div>
    </div>
  </Main>
</template>

<script>
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import ViewTable from "@/components/tables/ViewTable.vue";
import api from "@/services/api";
import fields from "./fields";

export default {
  components: { Main, Spinner, ViewTable },
  data() {
    return {
      data: {},
      error: null,
      loading: true,
      fields: fields,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const data = await api.user.get(this.$route.params.id);
      fields.forEach((column) => (this.data[column.key] = data[column.key]));
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
