<template>
  <Main>
    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <h1>Order #{{ order.id }}</h1>
      <h3>
        Site: <span class="fw-bold">{{ order.site.name }}</span>
      </h3>
      <div>
        <span class="fs-4 d-inline-block align-middle me-2">Status:</span>
        <OrderStatusBadge :status="order.status" />
      </div>

      <div class="row">
        <div class="col-lg-8">
          <div class="table-wrapper">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Code</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in order.orderItems" :key="item['id']">
                  <td>
                    {{ item.product.name }}
                  </td>
                  <td>
                    {{ item.product.code }}
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    {{ item.product.price }}
                  </td>
                  <td>
                    {{ item.product.price * item.quantity }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="text-end fs-3 fw-bold">
            Total:
            {{
              this.formatPrice(
                order.orderItems
                  .map((item) => item.product.price * item.quantity)
                  .reduce((a, b) => a + b, 0)
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import OrderStatusBadge from "@/components/widgets/OrderStatusBadge";
import api from "@/services/api";
import format from "@/services/format";
import fields from "./fields";

export default {
  components: { Main, Spinner, OrderStatusBadge },
  data() {
    return {
      order: {},
      error: null,
      loading: true,
      fields: fields,
    };
  },
  methods: {
    formatPrice(value) {
      return format.price(value);
    },
    async fetchData() {
      this.loading = true;
      this.order = await api.order.get(this.$route.params.id);
      this.loading = false;
    },
  },
  async mounted() {
    // this.fileds.forEach((column) => (this.data[column.key] = null));
    await this.fetchData();
  },
};
</script>
<style scoped>
.table-wrapper {
  overflow-x: auto;
}
</style>
