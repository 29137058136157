import store from "@/store/index.js";

export default {
  createFormKitSchema: (fields) => {
    let output = fields
      .filter((field) => field.formKit)
      .map((field) => {
        var component = field.formKit;
        component.props.name = field.key;
        component.props.id = field.key;
        component.props.label = field.name;
        component.props.value = "$" + field.key;
        if (!component["$cmp"]) {
          component["$cmp"] = "FormKit";
        }
        return component;
      });
    output.push({
      $el: "div",
      if: "$error",
      attrs: {
        class: "formkit-wrapper",
      },
      children: [
        {
          $el: "div",
          attrs: {
            class: "alert alert-danger",
          },
          children: "$error",
        },
      ],
    });
    return output;
  },
  mapOptions: (items) => {
    var options = {};
    items.forEach((item) => {
      options[item["@id"]] = item.name;
    });
    return options;
  },
  mapProductParameters: (parameters) => {
    let result = [];
    parameters.items.forEach((parameter) => {
      let units = [];
      parameter.units.forEach((unit) => {
        units.push({ key: unit["@id"], name: unit.name });
      });
      result.push({
        key: parameter["@id"],
        name: parameter.name,
        units: units,
      });
    });
    return result;
  },
  clearFormErrors: (node) => {
    store.dispatch("setError", null);
    node.clearErrors();
  },
  handleFormErrors: (node, errorResponse) => {
    if (errorResponse.errors.length) {
      let errors = {};
      errorResponse.errors.forEach((error) => {
        errors[error.path] = error.message;
      });
      node.setErrors(["Form has errors"], errors);
    } else {
      store.dispatch("setError", errorResponse.message);
    }
  },
};
