export default {
  formatRole: function (role) {
    switch (role) {
      case "ROLE_SUPER_ADMIN":
        return "Super admin";
      case "ROLE_COMPANY_ADMIN":
        return "Company admin";
      case "ROLE_SITE_LEADER":
        return "Site leader";
      case "ROLE_SUPPLIER":
        return "Supplier";
    }
  },
};
