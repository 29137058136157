export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Email",
    key: "email",
    hasListView: true,
    formKit: {
      props: {
        type: "email",
        validation: "required",
      },
    },
  },
  {
    name: "Password",
    key: "password",
    formKit: {
      props: {
        type: "password",
      },
    },
    viewable: false,
  },
  {
    name: "Firstname",
    key: "firstname",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Lastname",
    key: "lastname",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Id code",
    key: "idCode",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Phone",
    key: "phone",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
];
