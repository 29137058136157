import format from "@/services/format";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Company",
    key: "company",
    formatter: format.name,
  },
  {
    name: "Address",
    key: "address",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
      },
    },
  },
  {
    name: "Work Specification",
    key: "workSpecification",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
      },
    },
  },
];
