<template>
  <MainLayout>
    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <h1>Order #{{ order.id }}</h1>
      <h3>
        Site: <span class="fw-bold">{{ order.site.name }}</span>
      </h3>
      <div>
        <span class="fs-4 d-inline-block align-middle me-2">Status:</span>
        <OrderStatusBadge :status="order.status" />
      </div>

      <div class="row">
        <div class="mt-4 mb-4 col-sm-9 col-md-8 col-lg-6">
          <div
            class="order-orderItem"
            v-for="orderItem in order.orderItems"
            :key="orderItem.id"
          >
            <h5 class="fw-bold text-center text-primary">
              {{ orderItem.product.name }}
            </h5>
            <div class="row align-orderItems-center">
              <div class="col-4">
                <ProductImage :image="orderItem.product.image" />
              </div>
              <div class="col-4">
                <ProductParametersWithModal :product="orderItem.product" />
              </div>
              <div class="col-4">
                <div><strong>Quantity</strong>: {{ orderItem.quantity }}</div>
                <br />
                <div v-if="order.status === 'new'">
                  <button
                    v-if="!orderItem.confirmed"
                    class="btn btn-primary"
                    @click.prevent="onOrderItemConfirmed(orderItem.id)"
                  >
                    Confirm
                  </button>
                  <button
                    v-else
                    class="btn btn-danger"
                    @click.prevent="onOrderItemCancelled(orderItem.id)"
                  >
                    Cancel
                  </button>
                </div>
                <div v-else>
                  <span
                    v-if="orderItem.confirmed"
                    class="badge order-item-status bg-success"
                    >CONFIRMED</span
                  >
                  <span v-else class="badge order-item-status bg-danger"
                    >MISSING</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          v-if="order.status === 'new'"
          @click.prevent="onOrderCompleted()"
          class="btn btn-primary"
          :disabled="
            order.orderItems.filter((orderItem) => orderItem.confirmed)
              .length === 0
          "
        >
          Complete order
        </button>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import api from "@/services/api";
import fields from "./fields";
import MainLayout from "@/layouts/MainLayout.vue";
import ProductImage from "@/components/images/ProductImage";
import ProductParametersWithModal from "@/components/parts/ProductParametersWithModal";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import OrderStatusBadge from "@/components/widgets/OrderStatusBadge";

export default {
  components: {
    MainLayout,
    ProductImage,
    ProductParametersWithModal,
    Spinner,
    OrderStatusBadge,
  },
  data() {
    return {
      order: {},
      error: null,
      loading: true,
      fields: fields,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.order = await api.order.get(this.$route.params.id);
      this.loading = false;
    },
    async onOrderItemConfirmed(id) {
      this.order.orderItems.find(
        (orderItem) => orderItem.id === id
      ).confirmed = true;
      await api.orderItem.patch(id, { confirmed: true });
    },
    async onOrderItemCancelled(id) {
      this.order.orderItems.find(
        (orderItem) => orderItem.id === id
      ).confirmed = false;
      await api.orderItem.patch(id, { confirmed: false });
    },
    async onOrderCompleted() {
      await api.order.orderComplete(parseInt(this.$route.params.id));
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
<style scoped>
.order-orderItem {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.order-orderItem:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}
.order-item-status {
  font-size: 12px;
}

.product-image {
  max-width: 100px;
}
</style>
