<template>
  <div id="filter-form-spacer"></div>
  <div id="filter-form" class="px-3 py-2 mb-3">
    <form>
      <div class="row mb-3">
        <div class="col-7">
          <label for="product-search" class="form-label text-white"
            >Products</label
          >
          <div class="input-group">
            <input
              v-model="search"
              @input="onSearchInput"
              type="text"
              class="form-control"
              id="product-search"
              aria-describedby="search-icon"
              placeholder="Search"
            />
            <span class="input-group-text" id="search-icon"
              ><i class="bi bi-search"></i
            ></span>
          </div>
        </div>
        <div class="col-5">
          <label for="manufacturer-filter" class="form-label text-white"
            >Manufacturer</label
          >
          <select
            v-model="manufacturerId"
            @change="onManufacturerChange"
            id="manufacturer-filter"
            class="form-select"
          >
            <option value="" selected>All</option>
            <option
              v-for="manufacturer in manufacturers.items"
              :key="manufacturer.id"
              :value="manufacturer.id"
            >
              {{ manufacturer.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <Spinner v-if="loading"></Spinner>
  <div v-else>
    <div v-if="totalItems" id="product-list" class="row">
      <div
        class="col-lg-3 col-md-4 col-sm-12 pb-3"
        v-for="product in items"
        :key="product.id"
      >
        <div class="card product d-none d-md-block">
          <ProductImage :image="product.image" class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title" :title="product.name">
              {{ product.name }}
            </h5>
            <div class="row">
              <div class="col-6">
                <ProductParametersWithModal :product="product" />
              </div>
              <div class="col-6 text-end">
                <strong class="fs-3">{{ product.price }}€</strong>
                <div class="text-uppercase">
                  {{ product.manufacturer ? product.manufacturer.name : "" }}
                </div>
                <div class="mt-2">
                  <a
                    v-if="selectedProductsIdsData.indexOf(product.id) === -1"
                    href="#"
                    class="btn btn-primary btn-sm"
                    @click.prevent="onProductAdd(product.id)"
                  >
                    Add
                  </a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-danger btn-sm"
                    @click.prevent="onProductRemove(product.id)"
                  >
                    Remove
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product mobile d-block d-md-none pb-2 border-bottom">
          <h6 class="fw-bold text-center text-primary" :title="product.name">
            {{ product.name }}
          </h6>
          <div class="row">
            <div class="col-3">
              <ProductImage :image="product.image" />
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col-7">
                  <ProductParametersWithModal :product="product" />
                </div>
                <div class="col-5 text-end">
                  <strong class="fs-3">{{ product.price }}€</strong>
                  <div class="text-uppercase">
                    {{ product.manufacturer ? product.manufacturer.name : "" }}
                  </div>
                  <div class="mt-2">
                    <a
                      v-if="selectedProductsIdsData.indexOf(product.id) === -1"
                      href="#"
                      class="btn btn-primary btn-sm"
                      @click.prevent="onProductAdd(product.id)"
                    >
                      Add
                    </a>
                    <a
                      v-else
                      href="#"
                      class="btn btn-danger btn-sm"
                      @click.prevent="onProductRemove(product.id)"
                    >
                      Remove
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner v-if="fetching"></Spinner>
    </div>
    <div v-else>No products match your search</div>
  </div>
</template>

<script>
import api from "@/services/api";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import format from "@/services/format";
import ProductImage from "@/components/images/ProductImage";
import ProductParametersWithModal from "@/components/parts/ProductParametersWithModal";

export default {
  props: {
    selectedProductsIds: {
      type: Array,
    },
  },
  emits: ["productsSelectionChanged", "productAdded", "productRemoved"],
  components: {
    Spinner,
    ProductImage,
    ProductParametersWithModal,
  },
  data() {
    return {
      loading: false,
      format: format,
      selectedProductsIdsData: [],
      manufacturers: api.getEmptyCollection(),
      manufacturerId: "",
      search: "",
      showModal: false,
      items: [],
      totalItems: 0,
      page: 1,
      itemsPerPage: 20,
      fetching: false,
    };
  },
  watch: {
    selectedProductsIds() {
      this.updateSelectedProductsIds();
    },
  },
  methods: {
    getPagination() {
      return { page: this.page, itemsPerPage: this.itemsPerPage };
    },
    getSorting() {
      return { field: "name", order: "ASC" };
    },
    getFilters() {
      return {
        name: this.search,
        manufacturer: this.manufacturerId
          ? parseInt(this.manufacturerId)
          : null,
        accessLists: this.$store.state.accessList
          ? this.$store.state.accessList.id
          : null,
      };
    },
    async fetchData() {
      this.loading = true;
      await this.fetchProducts();
      this.manufacturers = await api.manufacturer.getAll();
      this.loading = false;
    },
    async fetchProducts() {
      this.fetching = true;
      let { items, totalItems } = await api.product.getAll(
        this.getPagination(),
        this.getSorting(),
        this.getFilters()
      );
      this.totalItems = totalItems;
      this.items = items;
      this.fetching = false;
    },
    async fetchProductsNext() {
      this.fetching = true;
      this.page = this.page + 1;
      let { items, totalItems } = await api.product.getAll(
        this.getPagination(),
        this.getSorting(),
        this.getFilters()
      );
      this.totalItems = totalItems;
      this.items = [...this.items, ...items];
      this.fetching = false;
    },
    async onSearchInput() {
      await this.fetchProducts(true);
    },
    async onManufacturerChange() {
      await this.fetchProducts(true);
    },
    async onProductAdd(productId) {
      if (this.selectedProductsIdsData.indexOf(productId) === -1) {
        this.selectedProductsIdsData.push(productId);
        this.$emit("productsSelectionChanged", this.selectedProductsIdsData);
        this.$emit("productAdded", productId);
      }
    },
    async onProductRemove(productId) {
      let index = this.selectedProductsIdsData.indexOf(productId);
      if (index >= 0) {
        this.selectedProductsIdsData.splice(index, 1);
        this.$emit("productsSelectionChanged", this.selectedProductsIdsData);
        this.$emit("productRemoved", productId);
      }
    },
    async updateSelectedProductsIds() {
      this.selectedProductsIdsData = [...this.selectedProductsIds];
    },
    async onScroll() {
      if (!this.fetching && this.page * this.itemsPerPage < this.totalItems) {
        let body = document.body,
          html = document.documentElement;
        let height = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );
        let spaceLeft = height - window.innerHeight - window.scrollY;
        if (spaceLeft < 600) {
          await this.fetchProductsNext();
        }
      }
    },
  },
  async mounted() {
    if (this.selectedProductsIds) {
      this.selectedProductsIdsData = [...this.selectedProductsIds];
    }
    await this.fetchData();
    window.addEventListener("scroll", this.onScroll);
  },
  async unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped lang="scss">
#filter-form {
  background-image: url("~@/assets/images/filter-background.png");
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgb(31 35 60 / 80%);
  @media (max-width: 767px) {
    padding-left: 12px !important;
    padding-right: 12px !important;
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
  }
}

#filter-form-spacer {
  display: none;
  @media (max-width: 767px) {
    display: block;
    height: 100px;
  }
}

#product-list .product .card-title {
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
}
</style>
