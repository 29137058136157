<script setup>
import { defineProps } from "vue";
import { useStore } from "vuex";

const store = useStore();
defineProps({
  id: String,
  className: String,
  isVisible: {
    type: Boolean,
    default: true,
  },
  accessLists: {
    type: Object,
    default: () => ({ items: [] }),
  },
  onAccessListSelect: {
    type: Function,
    default: () => {},
  },
  onLinkClicked: {
    type: Function,
    default: () => {},
  },
});
</script>

<template>
  <nav v-if="isVisible" :id="id" :class="className">
    <!-- Super admin -->
    <ul
      class="nav flex-column text-start fs-5"
      v-if="store.state.user.role === 'ROLE_SUPER_ADMIN'"
    >
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SuperAdminUserList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-people"></i> Users
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          to="/super-admin/manufacturers"
          @click="onLinkClicked"
        >
          <i class="bi bi-building"></i> Manufacturers
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          to="/super-admin/stores"
          @click="onLinkClicked"
        >
          <i class="bi bi-shop"></i> Stores
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          to="/super-admin/companies"
          @click="onLinkClicked"
        >
          <i class="bi bi-briefcase"></i> Companies
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          to="/super-admin/categories"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmarks"></i> Categories
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SuperAdminUnitList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-rulers"></i> Units
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SuperAdminParameterList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-view-stacked"></i> Parameters
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SuperAdminProductList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bag"></i> Products
        </router-link>
      </li>
    </ul>
    <!-- Company admin -->
    <ul
      class="nav flex-column text-start fs-5"
      v-if="store.state.user.role === 'ROLE_COMPANY_ADMIN'"
    >
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'CompanyAdminAccessListList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-list-check"></i> Access lists
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'CompanyAdminSiteList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-columns-gap"></i> Sites
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'CompanyAdminSiteLeaderList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-people-fill"></i> Site leaders
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'CompanyAdminSupplierList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-people"></i> Suppliers
        </router-link>
      </li>
    </ul>
    <!-- Site leader -->
    <ul
      class="nav flex-column text-start fs-5"
      v-if="store.state.user.role === 'ROLE_SITE_LEADER'"
    >
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SiteLeaderProductList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-box2"></i> Products
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SiteLeaderOrderList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-file-earmark-text"></i> Orders
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SiteLeaderAccessListSelect' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-list"></i> Access lists
        </router-link>
        <ul class="nav flex-column">
          <li class="nav-item" v-for="item in accessLists.items" :key="item.id">
            <a
              class="nav-link link-dark"
              href="#"
              @click.prevent="onAccessListSelect(item.id)"
            >
              &nbsp;&nbsp;&nbsp;-
              <span
                class="fw-bold"
                v-if="
                  store.state.accessList &&
                  store.state.accessList.id === item.id
                "
                >{{ item.name }}</span
              >
              <span v-else>{{ item.name }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SiteLeaderSupplierList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-people"></i> Suppliers
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link link-dark disabled" href="#">
          <i class="bi bi-tools"></i> Tools
        </a>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link link-dark disabled" href="#"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Enroll tool</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link link-dark disabled" href="#"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Exchange tool</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link link-dark disabled" href="#"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Deduct tool</a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-hammer"></i> Tools repair
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link link-dark disabled" href="#">
          <i class="bi bi-hammer"></i> Tools rent
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link link-dark disabled" href="#">
          <i class="bi bi-bag"></i> All product mode
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link link-dark disabled" href="#">
          <i class="bi bi-file-person"></i> Brigadier
        </a>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link link-dark disabled" href="#"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Create brigadier</a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmark"></i> My Contacts
        </router-link>
      </li>
    </ul>
    <!-- Supplier -->
    <ul
      class="nav flex-column text-start fs-5"
      v-if="store.state.user.role === 'ROLE_SUPPLIER'"
    >
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SupplierOrderNewList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-file-earmark-text"></i> Active orders
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark"
          :to="{ name: 'SupplierOrderHistoryList' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-file-earmark-check"></i> Order history
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmark"></i> Tools
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmark"></i> Tool repair
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmark"></i> Site Contacts
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmark"></i> Hub contact
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmark"></i> Map
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link link-dark disabled"
          :to="{ name: '' }"
          @click="onLinkClicked"
        >
          <i class="bi bi-bookmark"></i> Active Delay
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
.nav-item a {
  white-space: nowrap;
}
</style>
