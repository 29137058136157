<template>
  <Main>
    <h1>Edit product</h1>
    <div class="row">
      <div class="col-lg-8">
        <Spinner v-if="loading"></Spinner>
        <FormKit v-else type="form" @submit="onSubmit">
          <FormKitSchema :schema="schema" :data="data" />
        </FormKit>
      </div>
    </div>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      loading: true,
      fields: fields,
      schema: {},
      data: {},
    };
  },
  methods: {
    async onSubmit(formData) {
      await api.product.update(this.$route.params.id, formData);
      await this.$router.push({ name: "SuperAdminProductList" });
    },
    async fetchData() {
      this.loading = true;
      const categories = await api.category.getAll({ pagination: false });
      const manufacturers = await api.manufacturer.getAll({
        pagination: false,
      });
      const parameters = await api.parameter.getAll({ pagination: false });
      fields.forEach((field) => {
        if (field.key === "category") {
          categories.items.forEach((item) => {
            field.formKit.props.options[item["@id"]] = item.name;
          });
        } else if (field.key === "manufacturer") {
          manufacturers.items.forEach((item) => {
            field.formKit.props.options[item["@id"]] = item.name;
          });
        } else if (field.key === "productParameters") {
          field.formKit.props.options = forms.mapProductParameters(parameters);
        }
      });
      const data = await api.product.get(this.$route.params.id);
      this.fields.forEach((field) => {
        if (field.key === "category" || field.key === "manufacturer") {
          this.data[field.key] = data[field.key] ? data[field.key]["@id"] : "";
        } else {
          this.data[field.key] = data[field.key];
        }
      });
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchData();
    this.schema = forms.createFormKitSchema(fields);
  },
  components: { Main, Spinner, FormKit, FormKitSchema },
};
</script>
