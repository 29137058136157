<template>
  <Main>
    <h1>Create supplier</h1>
    <FormKit type="form" @submit="onSubmit">
      <FormKitSchema :schema="schema" />
    </FormKit>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      fields: fields,
      schema: {},
    };
  },
  methods: {
    async onSubmit(formData, node) {
      forms.clearFormErrors(node);
      formData.role = "ROLE_SUPPLIER";
      try {
        await api.user.create(formData);
        await this.$router.push({ name: "SiteLeaderSupplierList" });
      } catch (errorResponse) {
        forms.handleFormErrors(node, errorResponse);
      }
    },
    async createFormKitSchema() {
      this.schema = forms.createFormKitSchema(this.fields);
    },
  },
  async mounted() {
    await this.createFormKitSchema();
  },
  components: { Main, FormKit, FormKitSchema },
};
</script>
