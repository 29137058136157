const baseUrl = "https://back.ginex.eu";
const acceptHeader = { Accept: "application/ld+json" };
const contentTypeHeader = { "Content-Type": "application/json" };
const patchContentTypeHeader = {
  "Content-Type": "application/merge-patch+json",
};
const commonHeaders = {
  ...acceptHeader,
  ...contentTypeHeader,
};

async function processResponse(response) {
  var data = await response.json();
  if (response.ok) {
    return data;
  }
  var errors = data.violations
    ? data.violations.map((violation) => ({
        path: violation.propertyPath,
        message: violation.message,
      }))
    : [];
  throw { message: data["hydra:description"], errors };
}

async function processCollectionResponse(response) {
  var data = await response.json();
  if (response.ok) {
    return {
      items: data["hydra:member"],
      totalItems: data["hydra:totalItems"],
    };
  }
  var errors = data.violations
    ? data.violations.map((violation) => ({
        path: violation.propertyPath,
        message: violation.message,
      }))
    : [];
  throw { message: data["hydra:description"], errors };
}

async function processNoContentResponse(response) {
  if (response.ok) {
    return;
  }
  var data = await response.json();
  var errors = data.violations
    ? data.violations.map((violation) => ({
        path: violation.propertyPath,
        message: violation.message,
      }))
    : [];
  throw { message: data["hydra:description"], errors };
}

function handleError(error) {
  return { message: error, errors: [] };
}

function buildParams(data) {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key + "[]", value.toString()));
    } else if (typeof value === "object") {
      if (value === null) {
        params.append(key, "");
      } else {
        for (let [valKey, val] of Object.entries(value)) {
          params.append(key + "[" + valKey + "]", val.toString());
        }
      }
    } else {
      params.append(key, value.toString());
    }
  });

  return params.toString();
}

let common = {
  async getAll(slug, pagination, sorting, filters, params) {
    let url = baseUrl + "/api/" + slug;
    if (!params) {
      let params = {};
    }
    if (!filters) {
      let filters = {};
    }
    if (!pagination) {
      let pagination = { page: 1, itemsPerPage: 20 };
    }
    params = { ...pagination, ...filters, ...params };
    if (sorting) {
      params.order = { [sorting.field]: sorting.order.toLowerCase() };
    }
    let urlParams = buildParams(params);
    url += "?" + urlParams.toString();
    const response = await fetch(url, {
      method: "GET",
      headers: commonHeaders,
      credentials: "include",
    });
    return processCollectionResponse(response);
  },
  async get(slug, id, params) {
    let url = baseUrl + "/api/" + slug + "/" + id;
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: "GET",
      headers: commonHeaders,
      credentials: "include",
    });
    return processResponse(response);
  },
  async post(slug, data, params) {
    let url = baseUrl + "/api/" + slug;
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: "POST",
      headers: commonHeaders,
      credentials: "include",
      body: JSON.stringify(data),
    }).catch((error) => {
      throw handleError(error);
    });
    return processResponse(response);
  },
  async postForm(slug, data, params) {
    let url = baseUrl + "/api/" + slug;
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: data,
    }).catch((error) => {
      throw handleError(error);
    });
    return processResponse(response);
  },
  async put(slug, id, data, params) {
    let url = baseUrl + "/api/" + slug + "/" + id;
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: "PUT",
      headers: commonHeaders,
      credentials: "include",
      body: JSON.stringify(data),
    }).catch((error) => {
      throw handleError(error);
    });
    return processResponse(response);
  },
  async patch(slug, id, data, params) {
    let url = baseUrl + "/api/" + slug + "/" + id;
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: "PATCH",
      headers: { ...acceptHeader, ...patchContentTypeHeader },
      credentials: "include",
      body: JSON.stringify(data),
    }).catch((error) => {
      throw handleError(error);
    });
    return processResponse(response);
  },
  async delete(slug, id, params) {
    let url = baseUrl + "/api/" + slug + "/" + id;
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: "DELETE",
      headers: commonHeaders,
      credentials: "include",
    }).catch((error) => {
      throw handleError(error);
    });
    return processNoContentResponse(response);
  },
};

export default {
  getEmptyCollection: () => ({
    items: [],
    totalItems: 0,
  }),
  user: {
    login(email, password) {
      return fetch(baseUrl + "/api/login", {
        method: "POST",
        headers: commonHeaders,
        credentials: "include",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      }).catch((error) => {
        throw handleError(error);
      });
    },
    logout() {
      return fetch(baseUrl + "/api/logout").catch((error) => {
        throw handleError(error);
      });
    },
    async getCurrent() {
      var response = await fetch(baseUrl + "/api/user/current", {
        credentials: "include",
      }).catch((error) => {
        throw handleError(error);
      });
      return Promise.resolve({
        data: (await response.json()).data,
        status: response.status,
      });
    },
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("users", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("users", id);
    },
    async create(data) {
      return common.post("users", data);
    },
    async update(id, data) {
      return common.patch("users", id, data);
    },
    async delete(id) {
      await common.delete("users", id);
    },
  },
  manufacturer: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll(
        "manufacturers",
        pagination,
        sorting,
        filters,
        params
      );
    },
    async get(id) {
      return common.get("manufacturers", id);
    },
    async create(data) {
      return common.post("manufacturers", data);
    },
    async update(id, data) {
      return common.put("manufacturers", id, data);
    },
    async delete(id) {
      await common.delete("manufacturers", id);
    },
  },
  store: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("stores", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("stores", id);
    },
    async create(data) {
      return common.post("stores", data);
    },
    async update(id, data) {
      return common.patch("stores", id, data);
    },
    async delete(id) {
      await common.delete("stores", id);
    },
  },
  company: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("companies", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("companies", id);
    },
    async create(data) {
      return common.post("companies", data);
    },
    async update(id, data) {
      return common.patch("companies", id, data);
    },
    async delete(id) {
      await common.delete("companies", id);
    },
  },
  category: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("categories", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("categories", id);
    },
    async create(data) {
      return common.post("categories", data);
    },
    async update(id, data) {
      return common.patch("categories", id, data);
    },
    async delete(id) {
      await common.delete("categories", id);
    },
  },
  unit: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("units", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("units", id);
    },
    async create(data) {
      return common.post("units", data);
    },
    async update(id, data) {
      return common.patch("units", id, data);
    },
    async delete(id) {
      await common.delete("units", id);
    },
  },
  parameter: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("parameters", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("parameters", id);
    },
    async create(data) {
      return common.post("parameters", data);
    },
    async update(id, data) {
      return common.patch("parameters", id, data);
    },
    async delete(id) {
      await common.delete("parameters", id);
    },
  },
  product: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("products", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("products", id);
    },
    async create(data) {
      return common.post("products", data);
    },
    async update(id, data) {
      return common.patch("products", id, data);
    },
    async delete(id) {
      await common.delete("products", id);
    },
  },
  accessList: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll(
        "access_lists",
        pagination,
        sorting,
        filters,
        params
      );
    },
    async get(id) {
      return common.get("access_lists", id);
    },
    async create(data) {
      return common.post("access_lists", data);
    },
    async update(id, data) {
      return common.patch("access_lists", id, data);
    },
    async delete(id) {
      await common.delete("access_lists", id);
    },
  },
  cartItem: {
    async getAll(siteId) {
      var params = null;
      if (siteId) {
        params = { site: "/api/sites/" + siteId };
      }
      return common.getAll("cart_items", params);
    },
    async get(id) {
      return common.get("cart_items", id);
    },
    async create(data, siteId) {
      data.site = "/api/sites/" + siteId;
      return common.post("cart_items", data);
    },
    async put(id, data) {
      return common.put("cart_items", id, data);
    },
    async patch(id, data) {
      return common.patch("cart_items", id, data);
    },
    async delete(id) {
      await common.delete("cart_items", id);
    },
  },
  order: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("orders", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("orders", id);
    },
    async create(data) {
      return common.post("orders", data);
    },
    async delete(id) {
      await common.delete("orders", id);
    },
    async orderCreateFromCart(siteId) {
      let url = baseUrl + "/orders/new";
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ siteId: siteId }),
      });
      return response.json();
    },
    async orderComplete(id) {
      let url = baseUrl + "/orders/" + id + "/complete";
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      return response.json();
    },
  },
  orderItem: {
    async getAll() {
      return common.getAll("order_items");
    },
    async get(id) {
      return common.get("order_items", id);
    },
    async create(data) {
      return common.post("order_items", data);
    },
    async put(id, data) {
      return common.put("order_items", id, data);
    },
    async patch(id, data) {
      return common.patch("order_items", id, data);
    },
    async delete(id) {
      await common.delete("order_items", id);
    },
  },
  site: {
    async getAll(pagination, sorting, filters, params) {
      return common.getAll("sites", pagination, sorting, filters, params);
    },
    async get(id) {
      return common.get("sites", id);
    },
    async create(data) {
      return common.post("sites", data);
    },
    async update(id, data) {
      return common.patch("sites", id, data);
    },
    async delete(id) {
      await common.delete("sites", id);
    },
  },
};
