import "bootstrap-icons/font/bootstrap-icons.scss";
import "./assets/scss/app.scss";
import "@formkit/themes/dist/genesis/theme.css";
import { vfmPlugin } from "vue-final-modal";
import "bootstrap/dist/js/bootstrap.js";

import { createApp } from "vue";
import { plugin, defaultConfig, createInput } from "@formkit/vue";
import ProductParameters from "@/components/forms/form-kit/ProductParameters.vue";
import ProductList from "@/components/forms/form-kit/ProductList.vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
  .use(store)
  .use(
    plugin,
    defaultConfig({
      inputs: {
        productParameters: createInput(ProductParameters, {
          props: ["options"],
        }),
        productList: createInput(ProductList, {
          props: ["products"],
        }),
      },
    })
  )
  .use(router)
  .use(
    vfmPlugin({
      key: "$vfm",
      componentName: "VueFinalModal",
      dynamicContainerName: "ModalsContainer",
    })
  )
  .mount("#app");
