<template>
  <Main>
    <h1>Create parameter</h1>
    <FormKit type="form" @submit="onSubmit">
      <FormKitSchema :schema="schema" />
    </FormKit>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      fields: fields,
      schema: {},
    };
  },
  methods: {
    async onSubmit(formData) {
      await api.parameter.create(formData);
      this.$router.push({ name: "SuperAdminParameterList" });
    },
    async fetchData() {
      var units = await api.unit.getAll({ pagination: false });
      this.fields.forEach((field, index, object) => {
        if (field.key == "units") {
          if (units.items.length) {
            field.formKit.props.options = forms.mapOptions(units.items);
          } else {
            object.splice(index, 1);
          }
        }
      });
    },
  },
  async mounted() {
    await this.fetchData();
    this.schema = forms.createFormKitSchema(fields);
  },
  components: { Main, FormKit, FormKitSchema },
};
</script>
