<template>
  <Main>
    <h1>Create user</h1>
    <FormKit type="form" @submit="onSubmit">
      <FormKitSchema :schema="schema" :data="data" />
    </FormKit>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  components: {
    Main,
    FormKit,
    FormKitSchema,
  },
  data() {
    return {
      fields: fields,
      schema: [],
      data: {},
    };
  },
  methods: {
    async fetchData() {
      const companies = await api.company.getAll({ pagination: false });
      this.fields.forEach((field, index) => {
        switch (field.key) {
          case "company":
            this.fields[index].formKit.props.options = companies.items.map(
              (company) => ({
                value: company["@id"],
                label: company.name,
              })
            );
            break;
          case "password":
            this.fields[index].formKit.props.validation = "required";
            break;
        }
      });
    },
    async onSubmit(formData, node) {
      forms.clearFormErrors(node);
      if (formData.role === "ROLE_SUPER_ADMIN") {
        formData.company = null;
      }
      try {
        await api.user.create(formData);
        this.$router.push({ name: "SuperAdminUserList" });
      } catch (errorResponse) {
        forms.handleFormErrors(node, errorResponse);
      }
    },
  },
  async mounted() {
    await this.fetchData();
    this.schema = forms.createFormKitSchema(fields);
  },
};
</script>
