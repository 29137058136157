<template>
  <Main>
    <h1>Create unit</h1>
    <FormKit type="form" @submit="onSubmit">
      <FormKitSchema :schema="schema" />
    </FormKit>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      fields: fields,
    };
  },
  computed: {
    schema() {
      return forms.createFormKitSchema(fields);
    },
  },
  methods: {
    async onSubmit(formData) {
      await api.unit.create(formData);
      this.$router.push({ name: "SuperAdminUnitList" });
    },
  },
  components: { Main, FormKit, FormKitSchema },
};
</script>
