import moment from "moment";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Company name",
    key: "companyName",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Company registration code",
    key: "companyRegistrationCode",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Contact person name",
    key: "contactPersonName",
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Contact person phone",
    key: "contactPersonPhone",
    formKit: {
      props: {
        type: "tel",
        validation: "required",
      },
    },
  },
  {
    name: "Contact person email",
    key: "contactPersonEmail",
    formKit: {
      props: {
        type: "email",
        validation: "required",
      },
    },
  },
];
