<template>
  <Main>
    <h1>Create product</h1>
    <FormKit type="form" @submit="onSubmit">
      <FormKitSchema :schema="schema" />
    </FormKit>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      fields: fields,
      schema: {},
    };
  },
  methods: {
    async onSubmit(formData) {
      await api.product.create(formData);
      // await this.$router.push({ name: "SuperAdminProductList" });
    },
    async fetchData() {
      this.loading = true;
      const categories = await api.category.getAll({ pagination: false });
      const manufacturers = await api.manufacturer.getAll({
        pagination: false,
      });
      const parameters = await api.parameter.getAll({ pagination: false });
      fields.forEach((field) => {
        if (field.key === "category") {
          categories.items.forEach((item) => {
            field.formKit.props.options[item["@id"]] = item.name;
          });
        } else if (field.key === "manufacturer") {
          manufacturers.items.forEach((item) => {
            field.formKit.props.options[item["@id"]] = item.name;
          });
        } else if (field.key === "productParameters") {
          field.formKit.props.options = forms.mapProductParameters(parameters);
        }
      });
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchData();
    this.schema = forms.createFormKitSchema(fields);
  },
  components: { Main, FormKit, FormKitSchema },
};
</script>
