<template>
  <Main>
    <h1>Create access list</h1>
    <div class="row">
      <div class="col-lg-6 col-md-8">
        <form @submit.prevent="onSubmit">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Enter name"
              v-model="name"
              required
            />
          </div>
          <div class="mb-3">
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </Main>
</template>

<script>
import Main from "@/layouts/MainLayout.vue";
import api from "@/services/api";

export default {
  components: {
    Main,
  },
  data() {
    return {
      name: "",
    };
  },
  methods: {
    async onSubmit() {
      await api.accessList.create({ name: this.name });
      await this.$router.push({ name: "CompanyAdminAccessListList" });
    },
  },
};
</script>
