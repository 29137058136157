<template>
  <Main>
    <h1>Edit category</h1>
    <div class="row">
      <div class="col-lg-8">
        <Spinner v-if="loading"></Spinner>
        <FormKit v-else type="form" @submit="onSubmit">
          <FormKitSchema :schema="schema" :data="data" />
        </FormKit>
      </div>
    </div>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      id: parseInt(this.$route.params.id),
      loading: true,
      fields: fields,
      data: {},
      schema: {},
    };
  },
  methods: {
    async onSubmit(formData) {
      await api.category.update(this.id, formData);
      this.$router.push({ name: "SuperAdminCategoryList" });
    },
    async fetchData() {
      this.loading = true;
      var data = await api.category.get(this.id);
      this.fields.forEach((column) => {
        if (column.key === "parent" && data[column.key]) {
          this.data[column.key] = data[column.key]["@id"];
        } else {
          this.data[column.key] = data[column.key];
        }
      });
      var categories = await api.category.getAll({ pagination: false });
      fields.forEach((field) => {
        if (field.key === "parent") {
          field.formKit.props.options = [{ value: null, label: "No parent" }];
          categories.items.forEach((item) => {
            if (item.id !== this.id) {
              field.formKit.props.options.push({
                value: item["@id"],
                label: item.name,
              });
            }
          });
        }
      });
      this.loading = false;
    },
    async createFormKitSchema() {
      this.schema = forms.createFormKitSchema(this.fields);
    },
  },
  async mounted() {
    await this.fetchData();
    await this.createFormKitSchema();
  },
  components: { Main, Spinner, FormKit, FormKitSchema },
};
</script>
