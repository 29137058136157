<template>
  <Main>
    <h1>Suppliers</h1>
    <br />
    <router-link
      class="btn btn-primary"
      :to="{ name: 'CompanyAdminSupplierCreate' }"
    >
      Create
    </router-link>
    &nbsp;
    <button @click="onRefresh" class="btn btn-secondary">Refresh</button>
    <br />
    <br />
    <ListTable
      ref="listTable"
      :fields="fields"
      :defaultSortField="'id'"
      :defaultSortOrder="'ASC'"
      :apiResource="'user'"
      :fetchParams="{ role: 'ROLE_SUPPLIER' }"
      :searchField="'email'"
      :viewRoute="'CompanyAdminSupplierView'"
      :editRoute="'CompanyAdminSupplierEdit'"
    ></ListTable>
  </Main>
</template>

<script>
// @ is an alias to /src
import Main from "@/layouts/MainLayout.vue";
import ListTable from "@/components/tables/ListTable.vue";
import fields from "./fields";

export default {
  components: {
    Main,
    ListTable,
  },
  data() {
    return {
      fields: fields,
    };
  },
  methods: {
    onRefresh() {
      this.$refs.listTable.onRefresh();
    },
  },
};
</script>
