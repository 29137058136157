<script setup>
import { defineProps, ref } from "vue";
import ProductImage from "@/components/images/ProductImage";

const props = defineProps({
  product: Object,
  isVisible: Boolean,
});

let isVisible = ref(false);
</script>
<template>
  <a href="" @click.prevent="isVisible = true">Show more</a>
  <vue-final-modal
    v-model="isVisible"
    classes="modal-container"
    content-class="modal-content"
    :z-index-base="200000"
  >
    <button
      class="modal__close btn btn-primary btn-sm"
      @click="isVisible = false"
    >
      <i class="bi bi-x-lg"></i>
    </button>
    <span class="modal__title">{{ props.product.name }}</span>
    <div class="modal__content">
      <ProductImage :image="product.image" />
      <table class="table table-striped">
        <tbody>
          <tr
            v-for="productParameter in props.product.productParameters"
            :key="productParameter.id"
            scope="row"
          >
            <td class="fw-bold">{{ productParameter.parameter.name }}</td>
            <td>
              {{ productParameter.value
              }}<span v-if="productParameter.unit">{{
                productParameter.unit.name
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </vue-final-modal>
</template>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  max-width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
