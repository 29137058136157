<script>
import { th } from "@formkit/i18n";

export default {
  props: {
    totalItems: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    onPageNavigate: {
      type: Function,
    },
  },
  data() {
    return {};
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    pages() {
      let totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      if (totalPages < 5) {
        return Array.from(Array(totalPages).keys(), (page) => ({
          number: page + 1,
          isCurrent: page === this.currentPage,
        }));
      }
      return Array.from(Array(5).keys(), (page) => ({
        number:
          totalPages - this.currentPage < 2
            ? page + totalPages - 4
            : this.currentPage > 2
            ? page + this.currentPage - 2
            : page + 1,
        isCurrent: page === this.currentPage,
      }));
    },
  },
};
</script>

<template>
  <nav>
    <ul class="pagination justify-content-center">
      <li v-if="this.currentPage === 1" class="page-item disabled">
        <span class="page-link">First</span>
      </li>
      <li v-else class="page-item">
        <span class="page-link" @click="onPageNavigate(1)">First</span>
      </li>
      <li v-if="this.currentPage === 1" class="page-item disabled">
        <span class="page-link">Prev</span>
      </li>
      <li v-else class="page-item">
        <span class="page-link" @click="onPageNavigate(this.currentPage - 1)"
          >Prev</span
        >
      </li>
      <li
        v-for="page in pages"
        v-bind:key="page.number"
        class="page-item"
        :class="page.number === this.currentPage ? 'active' : ''"
        aria-current="page"
      >
        <a v-if="page.number === this.currentPage" class="page-link">{{
          page.number
        }}</a>
        <a v-else class="page-link" @click="onPageNavigate(page.number)">{{
          page.number
        }}</a>
      </li>
      <li
        v-if="this.currentPage === this.totalPages || totalItems === 0"
        class="page-item disabled"
      >
        <span class="page-link">Next</span>
      </li>
      <li v-else class="page-item">
        <span class="page-link" @click="onPageNavigate(this.currentPage + 1)"
          >Next</span
        >
      </li>
      <li
        v-if="this.currentPage === this.totalPages || totalItems === 0"
        class="page-item disabled"
      >
        <span class="page-link">Last</span>
      </li>
      <li v-else class="page-item">
        <span class="page-link" @click="onPageNavigate(this.totalPages)"
          >Last</span
        >
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
nav {
  display: inline-block;
}
.pagination {
  display: inline-flex;
  margin: 0;
  padding: 0;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */

  li:not(.active):not(.disabled) {
    cursor: pointer;
  }
}
</style>
