import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "@/router";
import api from "@/services/api";

export default createStore({
  state() {
    return {
      user: null,
      cartItems: {},
      siteId: null,
      accessList: null,
      errorMessage: null,
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setSiteId(state, id) {
      state.siteId = id;
    },
    setAccessList(state, item) {
      state.accessList = item;
    },
    setCartItems(state, cartItems) {
      state.cartItems = cartItems;
    },
    removeCartItem(state, id) {
      state.cartItems.items.forEach((cartItem, index) => {
        if (cartItem.id === id) {
          state.cartItems.items.splice(index, 1);
          state.cartItems.totalItems--;
        }
      });
    },
    emptyCartItems(state) {
      state.cartItems.items = [];
      state.cartItems.totalItems = 0;
    },
    updateCartItemQuantity(state, data) {
      state.cartItems.items.forEach((cartItem, index) => {
        if (cartItem.id === data.id) {
          state.cartItems.items[index].quantity = data.quantity;
        }
      });
    },
    setError(state, message) {
      state.errorMessage = message;
    },
  },
  actions: {
    async fetchCurrentUser({ commit }) {
      let response = await api.user.getCurrent();
      if (response.status === 401) {
        await commit("setUser", null);
        await router.push({ name: "Login" });
        return;
      }
      commit("setUser", response.data);
    },
    async fetchCartItems({ commit, state }) {
      if (state.siteId) {
        let response = await api.cartItem.getAll(state.siteId);
        commit("setCartItems", response);
        return;
      }
      commit("emptyCartItems");
    },
    async setSiteId({ commit, dispatch }, { siteId }) {
      await commit("setSiteId", siteId);
      await dispatch("fetchCartItems");
    },
    async setAccessList({ commit }, item) {
      await commit("setAccessList", item);
    },
    async fetchData({ dispatch, state }) {
      if (state.user) {
        await dispatch("fetchCurrentUser");
        await dispatch("fetchCartItems");
      }
    },
    async logout({ commit }) {
      await api.user.logout();
      await commit("setUser", null);
      await commit("setSiteId", null);
      await commit("setAccessList", null);
      await commit("emptyCartItems");
      await router.push({ name: "Login" });
    },
    setError({ commit }, message) {
      commit("setError", message);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
