export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Products",
    key: "products",
    formKit: {
      props: {
        type: "productList",
        classes: {
          wrapper: { "formkit-wrapper": false },
          inner: { "formkit-inner": false },
        },
      },
    },
  },
];
