<script setup>
import { defineProps, onMounted } from "vue";
import format from "@/services/format";
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  context: Object,
});

function onProductAdd($event) {
  var selectedProducts = props.context._value;
  if (selectedProducts.indexOf($event.target.dataset.id) === -1) {
    selectedProducts.push($event.target.dataset.id);
  }
}
function onProductRemove($event) {
  var selectedProducts = props.context._value;
  var index = selectedProducts.indexOf($event.target.dataset.id);
  if (index !== -1) {
    selectedProducts.splice(index, 1);
  }
}

function initData() {
  // if (props.context._value.value === undefined) {
  //   props.context.node.input([]);
  // }
}

onMounted(() => {
  initData();
});
</script>

<template>
  <div id="product-list" class="row">
    <div
      class="col-lg-3 col-md-4 col-sm-6 pb-3"
      v-for="product in props.context.products.items"
      :key="product.id"
    >
      <div class="card product d-none d-sm-block">
        <img
          v-if="product.image"
          :src="format.productImageThumbnailUrl(product.image)"
          class="card-img-top product-image"
          alt="product"
        />
        <img
          v-else
          src="@/assets/images/product-placeholder.png"
          class="card-img-top product-image"
          alt="product"
        />
        <div class="card-body">
          <h5 class="card-title">{{ product.name }}</h5>
          <p class="card-text">Code: {{ product.code }}</p>
          <h5>
            <strong>{{ product.price }}€</strong>
          </h5>
          <br />
          <a
            v-if="props.context._value.indexOf(product['@id']) === -1"
            class="btn btn-sm btn-primary"
            @click="onProductAdd($event)"
            :data-id="product['@id']"
            >Add to list</a
          >
          <a
            v-else
            class="btn btn-sm btn-danger"
            @click="onProductRemove($event)"
            :data-id="product['@id']"
            >Remove</a
          >
        </div>
      </div>
      <div class="product mobile d-block d-sm-none">
        <div class="row">
          <div class="col-4">
            <img
              v-if="product.image"
              :src="format.productImageThumbnailUrl(product.image)"
              class="card-img-top product-image"
              alt="product"
            />
            <img
              v-else
              src="@/assets/images/product-placeholder.png"
              class="card-img-top product-image"
              alt="product"
            />
          </div>
          <div class="col-8">
            <h5 class="" :title="product.name">
              {{ product.name }}
            </h5>
            <p class="">Code: {{ product.code }}</p>
            <div class="row">
              <div class="col-6">
                <h5>
                  <strong>{{ product.price }}€</strong>
                </h5>
              </div>
              <div class="col-6">
                <a
                  v-if="props.context._value.indexOf(product['@id']) === -1"
                  class="btn btn-sm btn-primary"
                  @click="onProductAdd($event)"
                  :data-id="product['@id']"
                  >Add to list</a
                >
                <a
                  v-else
                  class="btn btn-sm btn-danger"
                  @click="onProductRemove($event)"
                  :data-id="product['@id']"
                  >Remove</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#product-list .product .card-title {
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
}
</style>
