<template>
  <div class="table-wrapper">
    <SpinnerWidget v-if="loading" id="spinner"></SpinnerWidget>
    <div class="text-start" id="table-toolbar">
      <select
        @change="onItemsPerPageChange"
        class="form-select"
        id="items-per-page"
      >
        <option selected :value="10">10</option>
        <option :value="20">20</option>
        <option :value="50">50</option>
        <option :value="100">100</option>
        <option :value="200">200</option>
      </select>
      <span id="items-per-page-span">Items per page</span>
      <input
        @input="onSearchInput"
        id="search-input"
        class="form-control"
        type="text"
        placeholder="Search"
      />
    </div>
    <table
      class="table table-hover table-bordered align-middle"
      v-if="totalItems"
    >
      <thead>
        <tr>
          <template v-for="field in fields" :key="field.key">
            <th
              v-if="field.hasListView"
              class="text-nowrap"
              :class="
                field.key === this.sortField
                  ? this.sortOrder === 'ASC'
                    ? 'asc'
                    : 'desc'
                  : ''
              "
              scope="col"
              :data-sort="field.key"
              @click="this.onSort(field.key)"
            >
              {{ field.name }}
            </th>
          </template>
          <th class="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <template v-for="field in fields" :key="field.key">
            <td v-if="field.hasListView">
              <template v-if="item[field.key]">
                <span v-if="field.listFormatter">
                  <span v-html="field.listFormatter(item[field.key])"></span>
                </span>
                <span v-else-if="field.formatter">
                  <span v-html="field.formatter(item[field.key])"></span>
                </span>
                <span v-else>
                  {{ item[field.key] }}
                </span>
              </template>
              <template v-else>―</template>
            </td>
          </template>
          <td class="text-nowrap actions">
            <a
              v-if="viewRoute"
              href=""
              @click.prevent="onView(item.id)"
              class="me-2"
              title="View"
            >
              <i class="bi bi-eye-fill"></i>
            </a>
            <a
              v-if="editRoute"
              href=""
              @click.prevent="onEdit(item.id)"
              class="me-2"
              title="Edit"
            >
              <i class="bi bi-pencil-square"></i>
            </a>
            <span v-if="actions">
              <a
                v-for="action in actions"
                :key="action.name"
                href=""
                @click.prevent="action.callback(item.id)"
                :title="action.name"
                class="me-2"
              >
                <i :class="action.iconClass"></i>
              </a>
            </span>
            <a
              v-if="isDeletable"
              href=""
              @click.prevent="onDelete(item.id)"
              title="Delete"
            >
              <i class="bi bi-trash-fill"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p id="no-items-box" v-else>There are no items matching your search</p>
    <PaginatorWidget
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :currentPage="page"
      :onPageNavigate="onPageNavigate"
    />
  </div>
</template>

<script>
import PaginatorWidget from "@/components/widgets/PaginatorWidget.vue";
import SpinnerWidget from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";
export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
    defaultSortField: {
      type: String,
      required: true,
    },
    defaultSortOrder: {
      type: String,
      required: true,
    },
    searchField: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
    },
    apiResource: {
      type: String,
      required: true,
    },
    fetchParams: {
      type: Object,
      default: () => ({}),
    },
    viewRoute: {
      type: String,
    },
    editRoute: {
      type: String,
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      totalItems: 0,
      page: 1,
      itemsPerPage: 10,
      sortField: "id",
      sortOrder: "ASC",
      searchValue: "",
    };
  },
  methods: {
    onView(id) {
      if (this.viewRoute) {
        this.$router.push({
          name: this.viewRoute,
          params: { id: id },
        });
      }
    },
    onEdit(id) {
      if (this.editRoute) {
        this.$router.push({
          name: this.editRoute,
          params: { id: id },
        });
      }
    },
    onDelete(id) {
      this.loading = true;
      api[this.apiResource].delete(id).then(this.fetchData);
    },
    onRefresh() {
      this.fetchData();
    },
    onPageNavigate(page) {
      this.page = page;
    },
    onItemsPerPageChange(event) {
      this.itemsPerPage = parseInt(event.target.value);
      this.page = 1;
    },
    onSort(field) {
      if (field === this.sortField) {
        if (this.sortOrder === "ASC") {
          this.sortOrder = "DESC";
        } else {
          this.sortOrder = "ASC";
        }
      } else {
        this.sortField = field;
        this.sortOrder = "ASC";
      }
      this.page = 1;
    },
    onSearchInput(event) {
      this.searchValue = event.target.value;
      this.page = 1;
      if (event.target.value) {
        this.sortField = this.searchField;
        this.sortOrder = "ASC";
      }
    },
    async fetchData() {
      this.loading = true;
      const response = await api[this.apiResource].getAll(
        this.getPagination(),
        this.getSorting(),
        this.getFilters(),
        this.fetchParams
      );
      const { items, totalItems } = response;
      this.items = items;
      this.totalItems = totalItems;
      this.loading = false;
    },
    getPagination() {
      return { page: this.page, itemsPerPage: this.itemsPerPage };
    },
    getSorting() {
      let sortField =
        this.fields.find((field) => field.key === this.sortField).sortKey ??
        this.sortField;
      return { field: sortField, order: this.sortOrder };
    },
    getFilters() {
      return {
        [this.fields.find((field) => field.key === this.searchField)
          .searchKey ?? this.searchField]: this.searchValue,
      };
    },
  },
  async created() {
    this.sortField = this.defaultSortField;
    this.sortOrder = this.defaultSortOrder;
    await this.fetchData();
    this.$watch(
      () => [
        this.page,
        this.itemsPerPage,
        this.sortField,
        this.sortOrder,
        this.searchValue,
      ],
      this.fetchData
    );
  },
  components: {
    PaginatorWidget,
    SpinnerWidget,
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  position: relative;
  display: inline-block;
  text-align: center;
  table {
    text-align: left;
    margin-bottom: 15px;
    padding: 0;
    th,
    td {
      white-space: nowrap;
    }
    th.asc::after {
      content: "▲";
      margin-left: 5px;
    }
    th.desc::after {
      content: "▼";
      margin-left: 5px;
    }
    th:not(:nth-last-child(1)) {
      cursor: pointer;
      &:hover {
        color: #707070;
      }
    }
    th:not(:nth-last-child(1)):not(:nth-last-child(2)),
    td:not(:nth-last-child(1)):not(:nth-last-child(2)) {
      width: 50px;
    }
    .actions {
      width: 80px;
      text-align: center;
    }
  }
}
#table-toolbar {
  margin-bottom: 15px;
  overflow: visible;
  // margin: -10px;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
#items-per-page {
  float: left;
  width: auto;
  outline: none;
  &:focus {
    box-shadow: none;
  }
}
#items-per-page-span {
  float: left;
  line-height: 40px;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}
#no-items-box {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
}
#search-input {
  float: right;
  width: 250px;
  &:focus {
    box-shadow: none;
  }
}
#spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000;
}
</style>
