<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import api from "@/services/api";
import MainLayout from "@/layouts/MainLayout";

const store = useStore();
const router = useRouter();
let accessLists = ref(api.getEmptyCollection());

async function fetchData() {
  accessLists.value = await api.accessList.getAll();
}
async function selectItem(item) {
  await store.dispatch("setAccessList", item);
  await router.push({ name: "SiteLeaderProductList" });
}

onMounted(async () => {
  await fetchData();
});
</script>

<template>
  <MainLayout>
    <h1>Select access list</h1>
    <br />
    <div id="item-selector" class="list-group">
      <a
        v-for="item in accessLists.items"
        :key="item.id"
        @click.prevent="selectItem(item)"
        href="#"
        class="list-group-item list-group-item-action"
        >{{ item.name }}</a
      >
    </div>
  </MainLayout>
</template>

<style scoped>
#item-selector {
  max-width: 500px;
}
</style>
