import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";

// Route components
// Common
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";

// Super admin
import SuperAdminUserList from "../views/super-admin/user/UserList.vue";
import SuperAdminUserCreate from "../views/super-admin/user/UserCreate.vue";
import SuperAdminUserView from "../views/super-admin/user/UserView.vue";
import SuperAdminUserEdit from "../views/super-admin/user/UserEdit.vue";

import SuperAdminManufacturerList from "../views/super-admin/manufacturer/ManufacturerList.vue";
import SuperAdminManufacturerCreate from "../views/super-admin/manufacturer/ManufacturerCreate.vue";
import SuperAdminManufacturerView from "../views/super-admin/manufacturer/ManufacturerView.vue";
import SuperAdminManufacturerEdit from "../views/super-admin/manufacturer/ManufacturerEdit.vue";

import SuperAdminStoreList from "../views/super-admin/store/StoreList.vue";
import SuperAdminStoreCreate from "../views/super-admin/store/StoreCreate.vue";
import SuperAdminStoreView from "../views/super-admin/store/StoreView.vue";
import SuperAdminStoreEdit from "../views/super-admin/store/StoreEdit.vue";

import SuperAdminCompanyList from "../views/super-admin/company/CompanyList.vue";
import SuperAdminCompanyCreate from "../views/super-admin/company/CompanyCreate.vue";
import SuperAdminCompanyView from "../views/super-admin/company/CompanyView.vue";
import SuperAdminCompanyEdit from "../views/super-admin/company/CompanyEdit.vue";

import SuperAdminCategoryList from "../views/super-admin/category/CategoryList.vue";
import SuperAdminCategoryCreate from "../views/super-admin/category/CategoryCreate.vue";
import SuperAdminCategoryView from "../views/super-admin/category/CategoryView.vue";
import SuperAdminCategoryEdit from "../views/super-admin/category/CategoryEdit.vue";

import SuperAdminUnitList from "../views/super-admin/unit/UnitList.vue";
import SuperAdminUnitCreate from "../views/super-admin/unit/UnitCreate.vue";
import SuperAdminUnitView from "../views/super-admin/unit/UnitView.vue";
import SuperAdminUnitEdit from "../views/super-admin/unit/UnitEdit.vue";

import SuperAdminParameterList from "../views/super-admin/parameter/ParameterList.vue";
import SuperAdminParameterCreate from "../views/super-admin/parameter/ParameterCreate.vue";
import SuperAdminParameterView from "../views/super-admin/parameter/ParameterView.vue";
import SuperAdminParameterEdit from "../views/super-admin/parameter/ParameterEdit.vue";

import SuperAdminProductList from "../views/super-admin/product/ProductList.vue";
import SuperAdminProductCreate from "../views/super-admin/product/ProductCreate.vue";
import SuperAdminProductView from "../views/super-admin/product/ProductView.vue";
import SuperAdminProductEdit from "../views/super-admin/product/ProductEdit.vue";

// Company admin
import CompanyAdminAccessListList from "../views/company-admin/access-list/AccessListList.vue";
import CompanyAdminAccessListCreate from "../views/company-admin/access-list/AccessListCreate.vue";
import CompanyAdminAccessListEdit from "../views/company-admin/access-list/AccessListEdit.vue";
import CompanyAdminAccessListEditProducts from "../views/company-admin/access-list/AccessListEditProducts.vue";

import CompanyAdminSiteList from "../views/company-admin/site/SiteList.vue";
import CompanyAdminSiteCreate from "../views/company-admin/site/SiteCreate.vue";
import CompanyAdminSiteView from "../views/company-admin/site/SiteView.vue";
import CompanyAdminSiteEdit from "../views/company-admin/site/SiteEdit.vue";

import CompanyAdminSiteLeaderList from "../views/company-admin/site-leader/SiteLeaderList.vue";
import CompanyAdminSiteLeaderCreate from "../views/company-admin/site-leader/SiteLeaderCreate.vue";
import CompanyAdminSiteLeaderView from "../views/company-admin/site-leader/SiteLeaderView.vue";
import CompanyAdminSiteLeaderEdit from "../views/company-admin/site-leader/SiteLeaderEdit.vue";

import CompanyAdminSupplierList from "../views/company-admin/supplier/SupplierList.vue";
import CompanyAdminSupplierCreate from "../views/company-admin/supplier/SupplierCreate.vue";
import CompanyAdminSupplierView from "../views/company-admin/supplier/SupplierView.vue";
import CompanyAdminSupplierEdit from "../views/company-admin/supplier/SupplierEdit.vue";

// Site leader
import SiteLeaderProductList from "../views/site-leader/product/ProductList.vue";
import SiteLeaderOrderList from "../views/site-leader/order/OrderList.vue";
import SiteLeaderOrderView from "../views/site-leader/order/OrderView.vue";

import SiteLeaderCartList from "../views/site-leader/cart/CartList.vue";
import SiteLeaderSiteSelect from "../views/site-leader/SiteSelect.vue";
import SiteLeaderAccessListSelect from "../views/site-leader/AccessListSelect.vue";

import SiteLeaderSupplierList from "../views/site-leader/supplier/SupplierList.vue";
import SiteLeaderSupplierCreate from "../views/site-leader/supplier/SupplierCreate.vue";
import SiteLeaderSupplierView from "../views/site-leader/supplier/SupplierView.vue";
import SiteLeaderSupplierEdit from "../views/site-leader/supplier/SupplierEdit.vue";

// Supplier
import SupplierOrderNewList from "../views/suppiler/order/OrderNewList.vue";
import SupplierOrderHistoryList from "../views/suppiler/order/OrderHistoryList.vue";
import SupplierOrderView from "../views/suppiler/order/OrderView.vue";

const routes = [
  // Common
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // Super admin
  {
    path: "/super-admin/users",
    name: "SuperAdminUserList",
    component: SuperAdminUserList,
  },
  {
    path: "/super-admin/users/create",
    name: "SuperAdminUserCreate",
    component: SuperAdminUserCreate,
  },
  {
    path: "/super-admin/users/view/:id",
    name: "SuperAdminUserView",
    component: SuperAdminUserView,
  },
  {
    path: "/super-admin/users/edit/:id",
    name: "SuperAdminUserEdit",
    component: SuperAdminUserEdit,
  },
  {
    path: "/super-admin/manufacturers",
    name: "SuperAdminManufacturerList",
    component: SuperAdminManufacturerList,
  },
  {
    path: "/super-admin/manufacturers/create",
    name: "SuperAdminManufacturerCreate",
    component: SuperAdminManufacturerCreate,
  },
  {
    path: "/super-admin/manufacturers/:id",
    name: "SuperAdminManufacturerView",
    component: SuperAdminManufacturerView,
  },
  {
    path: "/super-admin/manufacturers/edit/:id",
    name: "SuperAdminManufacturerEdit",
    component: SuperAdminManufacturerEdit,
  },
  {
    path: "/super-admin/stores",
    name: "SuperAdminStoreList",
    component: SuperAdminStoreList,
  },
  {
    path: "/super-admin/stores/create",
    name: "SuperAdminStoreCreate",
    component: SuperAdminStoreCreate,
  },
  {
    path: "/stores/view/:id",
    name: "SuperAdminStoreView",
    component: SuperAdminStoreView,
  },
  {
    path: "/super-admin/stores/edit/:id",
    name: "SuperAdminStoreEdit",
    component: SuperAdminStoreEdit,
  },
  {
    path: "/super-admin/companies",
    name: "SuperAdminCompanyList",
    component: SuperAdminCompanyList,
  },
  {
    path: "/super-admin/companies/create",
    name: "SuperAdminCompanyCreate",
    component: SuperAdminCompanyCreate,
  },
  {
    path: "/super-admin/companies/view/:id",
    name: "SuperAdminCompanyView",
    component: SuperAdminCompanyView,
  },
  {
    path: "/super-admin/companies/edit/:id",
    name: "SuperAdminCompanyEdit",
    component: SuperAdminCompanyEdit,
  },
  {
    path: "/super-admin/categories",
    name: "SuperAdminCategoryList",
    component: SuperAdminCategoryList,
  },
  {
    path: "/super-admin/categories/create",
    name: "SuperAdminCategoryCreate",
    component: SuperAdminCategoryCreate,
  },
  {
    path: "/super-admin/categories/view/:id",
    name: "SuperAdminCategoryView",
    component: SuperAdminCategoryView,
  },
  {
    path: "/super-admin/categories/edit/:id",
    name: "SuperAdminCategoryEdit",
    component: SuperAdminCategoryEdit,
  },
  {
    path: "/super-admin/units",
    name: "SuperAdminUnitList",
    component: SuperAdminUnitList,
  },
  {
    path: "/super-admin/units/create",
    name: "SuperAdminUnitCreate",
    component: SuperAdminUnitCreate,
  },
  {
    path: "/super-admin/units/view/:id",
    name: "SuperAdminUnitView",
    component: SuperAdminUnitView,
  },
  {
    path: "/super-admin/units/edit/:id",
    name: "SuperAdminUnitEdit",
    component: SuperAdminUnitEdit,
  },
  {
    path: "/super-admin/parameters",
    name: "SuperAdminParameterList",
    component: SuperAdminParameterList,
  },
  {
    path: "/super-admin/parameters/create",
    name: "SuperAdminParameterCreate",
    component: SuperAdminParameterCreate,
  },
  {
    path: "/super-admin/parameters/view/:id",
    name: "SuperAdminParameterView",
    component: SuperAdminParameterView,
  },
  {
    path: "/super-admin/parameters/edit/:id",
    name: "SuperAdminParameterEdit",
    component: SuperAdminParameterEdit,
  },
  {
    path: "/super-admin/products",
    name: "SuperAdminProductList",
    component: SuperAdminProductList,
  },
  {
    path: "/super-admin/products/create",
    name: "SuperAdminProductCreate",
    component: SuperAdminProductCreate,
  },
  {
    path: "/super-admin/products/view/:id",
    name: "SuperAdminProductView",
    component: SuperAdminProductView,
  },
  {
    path: "/super-admin/products/edit/:id",
    name: "SuperAdminProductEdit",
    component: SuperAdminProductEdit,
  },

  // Company admin
  // Access lists
  {
    path: "/company-admin/access-lists/view-all",
    name: "CompanyAdminAccessListList",
    component: CompanyAdminAccessListList,
  },
  {
    path: "/company-admin/access-lists/create",
    name: "CompanyAdminAccessListCreate",
    component: CompanyAdminAccessListCreate,
  },
  {
    path: "/company-admin/access-lists/edit/:id",
    name: "CompanyAdminAccessListEdit",
    component: CompanyAdminAccessListEdit,
  },
  {
    path: "/company-admin/access-lists/edit-products/:id",
    name: "CompanyAdminAccessListEditProducts",
    component: CompanyAdminAccessListEditProducts,
  },
  // Sites
  {
    path: "/company-admin/sites/view-all",
    name: "CompanyAdminSiteList",
    component: CompanyAdminSiteList,
  },
  {
    path: "/company-admin/sites/create",
    name: "CompanyAdminSiteCreate",
    component: CompanyAdminSiteCreate,
  },
  {
    path: "/company-admin/sites/view/:id",
    name: "CompanyAdminSiteView",
    component: CompanyAdminSiteView,
  },
  {
    path: "/company-admin/sites/edit/:id",
    name: "CompanyAdminSiteEdit",
    component: CompanyAdminSiteEdit,
  },
  // Site leaders
  {
    path: "/company-admin/site-leaders",
    name: "CompanyAdminSiteLeaderList",
    component: CompanyAdminSiteLeaderList,
  },
  {
    path: "/company-admin/site-leaders/create",
    name: "CompanyAdminSiteLeaderCreate",
    component: CompanyAdminSiteLeaderCreate,
  },
  {
    path: "/company-admin/site-leaders/view/:id",
    name: "CompanyAdminSiteLeaderView",
    component: CompanyAdminSiteLeaderView,
  },
  {
    path: "/company-admin/site-leaders/edit/:id",
    name: "CompanyAdminSiteLeaderEdit",
    component: CompanyAdminSiteLeaderEdit,
  },
  // Suppliers
  {
    path: "/company-admin/suppliers",
    name: "CompanyAdminSupplierList",
    component: CompanyAdminSupplierList,
  },
  {
    path: "/company-admin/suppliers/create",
    name: "CompanyAdminSupplierCreate",
    component: CompanyAdminSupplierCreate,
  },
  {
    path: "/company-admin/suppliers/view/:id",
    name: "CompanyAdminSupplierView",
    component: CompanyAdminSupplierView,
  },
  {
    path: "/company-admin/suppliers/edit/:id",
    name: "CompanyAdminSupplierEdit",
    component: CompanyAdminSupplierEdit,
  },

  // Site leader
  // Site selector
  {
    path: "/site-leader/sites/select",
    name: "SiteLeaderSiteSelect",
    component: SiteLeaderSiteSelect,
  },
  // Access list selector
  {
    path: "/site-leader/access-lists/select",
    name: "SiteLeaderAccessListSelect",
    component: SiteLeaderAccessListSelect,
  },
  // Products
  {
    path: "/site-leader/products",
    name: "SiteLeaderProductList",
    component: SiteLeaderProductList,
  },
  // Cart
  {
    path: "/site-leader/cart",
    name: "SiteLeaderCartList",
    component: SiteLeaderCartList,
  },
  // Orders
  {
    path: "/site-leader/orders",
    name: "SiteLeaderOrderList",
    component: SiteLeaderOrderList,
  },
  {
    path: "/site-leader/orders/view/:id",
    name: "SiteLeaderOrderView",
    component: SiteLeaderOrderView,
  },
  // Suppliers
  {
    path: "/site-leader/suppliers",
    name: "SiteLeaderSupplierList",
    component: SiteLeaderSupplierList,
  },
  {
    path: "/site-leader/suppliers/create",
    name: "SiteLeaderSupplierCreate",
    component: SiteLeaderSupplierCreate,
  },
  {
    path: "/site-leader/suppliers/view/:id",
    name: "SiteLeaderSupplierView",
    component: SiteLeaderSupplierView,
  },
  {
    path: "/site-leader/suppliers/edit/:id",
    name: "SiteLeaderSupplierEdit",
    component: SiteLeaderSupplierEdit,
  },

  // Supplier
  // Orders
  {
    path: "/supplier/orders/new",
    name: "SupplierOrderNewList",
    component: SupplierOrderNewList,
  },
  {
    path: "/supplier/orders/history",
    name: "SupplierOrderHistoryList",
    component: SupplierOrderHistoryList,
  },
  {
    path: "/supplier/orders/view/:id",
    name: "SupplierOrderView",
    component: SupplierOrderView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== "Login" && !store.state.user) {
    // Check if session is active
    await store.dispatch("fetchCurrentUser");
  }
  if (to.name === "Login" && store.state.user) {
    next({ name: "Home" });
    return;
  }
  if (store.state.user) {
    if (store.state.user.role === "ROLE_SITE_LEADER") {
      if (to.name === "Home") {
        next({ name: "SiteLeaderProductList" });
      }
      if (!store.state.siteId && to.name !== "SiteLeaderSiteSelect") {
        next({ name: "SiteLeaderSiteSelect" });
        return;
      }
      if (
        store.state.accessList === null &&
        to.name !== "SiteLeaderAccessListSelect" &&
        to.name !== "SiteLeaderSiteSelect"
      ) {
        next({ name: "SiteLeaderAccessListSelect" });
        return;
      }
    }
    if (store.state.user.role === "ROLE_COMPANY_ADMIN") {
      if (to.name === "Home") {
        next({ name: "CompanyAdminAccessListList" });
      }
    }
    if (store.state.user.role === "ROLE_SUPPLIER") {
      if (to.name === "Home") {
        next({ name: "SupplierOrderNewList", params: { type: "active" } });
      }
    }
    if (store.state.user.role === "ROLE_SUPER_ADMIN") {
      if (to.name === "Home") {
        next({ name: "SuperAdminUserList", params: { type: "active" } });
      }
    }
  }
  next();
});

export default router;
