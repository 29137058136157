<template>
  <Main>
    <h1>Create category</h1>
    <Spinner v-if="loading"></Spinner>
    <FormKit v-else type="form" @submit="onSubmit">
      <FormKitSchema :schema="schema" />
    </FormKit>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";
import fields from "./fields";
import forms from "@/services/forms";

export default {
  components: { Main, Spinner, FormKit, FormKitSchema },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      loading: true,
      fields: fields,
      schema: {},
    };
  },
  methods: {
    async onSubmit(formData) {
      await api.category.create(formData);
      this.$router.push({ name: "SuperAdminCategoryList" });
    },
    async fetchData() {
      this.loading = true;
      var categories = await api.category.getAll({ pagination: false });
      fields.forEach((field) => {
        if (field.key === "parent") {
          field.formKit.props.options = [{ value: null, label: "No parent" }];
          categories.items.forEach((item) => {
            field.formKit.props.options.push({
              value: item["@id"],
              label: item.name,
            });
          });
        }
      });
      this.loading = false;
    },
    async createFormKitSchema() {
      this.schema = forms.createFormKitSchema(this.fields);
    },
  },
  async mounted() {
    await this.fetchData();
    await this.createFormKitSchema();
  },
};
</script>
