<template>
  <CenterLayout>
    <div class="text-start">
      <h1>Ginex</h1>
      <form @submit.prevent="onSubmit">
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input
            type="email"
            id="email"
            v-model="email"
            class="form-control"
            aria-describedby="emailHelp"
            required
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            id="password"
            v-model="password"
            class="form-control"
            required
          />
        </div>
        <div class="form-text text-danger mb-3" v-if="error">
          {{ this.error }}
        </div>
        <button type="submit" class="btn btn-primary" :disabled="loading">
          Log in
        </button>
      </form>
    </div>
  </CenterLayout>
</template>

<script>
// @ is an alias to /src
import CenterLayout from "@/layouts/CenterLayout.vue";
import api from "../services/api";

export default {
  components: {
    CenterLayout,
  },
  data() {
    return {
      email: null,
      password: null,
      error: null,
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(function () {});
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.user.login(this.email, this.password);
        if (response.status === 200) {
          response.json().then((data) => {
            this.$store.commit("setUser", data.data);
            this.$store.dispatch("fetchData");
            this.loading = false;
            document.body.className = "";
            this.$router.push("/");
          });
        } else {
          this.error = "Invalid credentials";
          this.loading = false;
        }
      } catch (e) {
        this.error = e;
        this.loading = false;
      }
    },
  },
  beforeCreate() {
    document.body.className = "login";
  },
};
</script>

<style>
body.login {
  background-image: url("~@/assets/images/login-background.png");
  background-position: center center;
  background-repeat: no-repeat;
  /*background-size: cover;*/
}
</style>
