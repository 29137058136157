export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
];
