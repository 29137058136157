<template>
  <Main>
    <h1>Edit site leader</h1>
    <div class="row">
      <div class="col-lg-8">
        <Spinner v-if="loading"></Spinner>
        <FormKit v-else type="form" @submit="onSubmit">
          <FormKitSchema :schema="schema" :data="data" />
        </FormKit>
      </div>
    </div>
  </Main>
</template>

<script>
import { FormKit, FormKitSchema } from "@formkit/vue";
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import api from "@/services/api";
import forms from "@/services/forms";
import fields from "./fields";

export default {
  data() {
    return {
      loading: true,
      fields: fields,
      data: {},
      schema: {},
    };
  },
  methods: {
    async onSubmit(formData, node) {
      forms.clearFormErrors(node);
      formData.role = "ROLE_SITE_LEADER";
      try {
        await api.user.update(this.$route.params.id, formData);
        await this.$router.push({ name: "CompanyAdminSiteLeaderList" });
      } catch (errorResponse) {
        forms.handleFormErrors(node, errorResponse);
      }
    },
    async createFormKitSchema() {
      this.schema = forms.createFormKitSchema(this.fields);
    },
    async fetchData() {
      this.loading = true;
      const data = await api.user.get(this.$route.params.id);
      this.fields.forEach(
        (column) => (this.data[column.key] = data[column.key])
      );
      this.loading = false;
    },
  },
  async mounted() {
    await this.createFormKitSchema();
    await this.fetchData();
  },
  components: { Main, Spinner, FormKit, FormKitSchema },
};
</script>
