<script setup>
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
let loading = ref(true);

onBeforeMount(async () => {
  await store.dispatch("fetchData");
  loading.value = false;
});
</script>
<template>
  <router-view v-if="!loading" />
</template>

<style scoped lang="scss">
@import "assets/scss/app.scss";
</style>
