<template>
  <Main>
    <h1 class="d-none d-md-block">
      Edit <strong>#{{ accessList.id }} {{ accessList.name }}</strong> products
    </h1>
    <Spinner v-if="loading"></Spinner>
    <div v-else class="row">
      <ProductList
        :selected-products-ids="selectedProductsIds"
        @products-selection-changed="onProductsSelectionChanged"
      ></ProductList>
    </div>
  </Main>
</template>

<script>
import Main from "@/layouts/MainLayout.vue";
import Spinner from "@/components/widgets/SpinnerWidget.vue";
import ProductList from "@/components/lists/ProductList";
import api from "@/services/api";

export default {
  components: {
    ProductList,
    Main,
    Spinner,
  },
  data() {
    return {
      loading: true,
      accessList: { products: [] },
      selectedProductsIds: [],
    };
  },
  methods: {
    async onProductsSelectionChanged(selectedProductsIds) {
      this.selectedProductsIds = selectedProductsIds;
      await api.accessList.update(this.$route.params.id, {
        products: this.selectedProductsIds.map(
          (productId) => "/api/products/" + productId
        ),
      });
    },
    async fetchData() {
      this.loading = true;
      this.accessList = await api.accessList.get(this.$route.params.id);
      this.selectedProductsIds = this.accessList.products.map(
        (item) => item.id
      );
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
