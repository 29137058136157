<template>
  <header>
    <nav
      id="navbar-top"
      class="navbar navbar-expand fixed-top navbar-light bg-light m-0 p-0"
    >
      <div class="container-fluid">
        <router-link class="navbar-brand fs-2" to="/">Ginex</router-link>
        <div class="justify-content-end">
          <ul class="navbar-nav mb-lg-0 align-items-center">
            <li
              v-if="$store.state.user.role === 'ROLE_SITE_LEADER'"
              class="nav-item me-2"
            >
              <select
                class="form-select"
                aria-label="Default select example"
                @change="onSiteSelect"
              >
                <option :selected="!$store.state.siteId" disabled>
                  Select site
                </option>
                <option
                  v-for="site in sites.items"
                  :value="site.id"
                  :key="site.id"
                  :selected="$store.state.siteId === site.id"
                >
                  {{ site.name }}
                </option>
              </select>
            </li>
            <li
              v-if="$store.state.user.role === 'ROLE_SITE_LEADER'"
              class="nav-item"
            >
              <router-link
                class="nav-link position-relative fs-4"
                :to="{ name: 'SiteLeaderCartList' }"
              >
                <i class="bi bi-cart-fill"></i>
                <span
                  v-if="
                    $store.state.cartItems
                      ? $store.state.cartItems.totalItems > 0
                      : false
                  "
                  class="position-absolute start-80 translate-middle badge rounded-pill bg-danger"
                >
                  {{ $store.state.cartItems.totalItems }}
                </span>
              </router-link>
            </li>
            <!--            <li class="nav-item">-->
            <!--              <a class="nav-link position-relative fs-4" href="#">-->
            <!--                <i class="bi bi-bell-fill"></i>-->
            <!--                <span-->
            <!--                  class="position-absolute top-20 start-80 translate-middle badge rounded-pill bg-danger"-->
            <!--                >-->
            <!--                  99+-->
            <!--                </span>-->
            <!--              </a>-->
            <!--            </li>-->
            <li class="nav-item dropdown">
              <a
                class="nav-link fs-4"
                href="#"
                id="userDropdownMenuButton"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-person-fill"></i>
              </a>
              <ul
                id="userDropdownMenu"
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="userDropdownMenuButton"
              >
                <li>
                  <a class="dropdown-item disabled" href="#">
                    {{ $store.state.user.firstname }}
                    {{ $store.state.user.lastname }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item disabled" href="#">{{
                    user.formatRole($store.state.user.role)
                  }}</a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click="onLogOut"
                    >Log out</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item d-block d-md-none">
              <a
                class="nav-link position-relative fs-4"
                @click.prevent="toggleMenu"
              >
                <i class="bi bi-list"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <MainMenu
      id="mobile-menu"
      className="d-block d-md-none"
      :is-visible="isMobileMenuVisible"
      :access-lists="accessLists"
      :on-access-list-select="onAccessListSelect"
      :on-link-clicked="onMenuLinkClicked"
    ></MainMenu>
  </header>
  <div id="main-container" class="container-fluid" :data-route="$route.name">
    <div class="row">
      <MainMenu
        id="desktop-menu"
        className="d-none d-md-block col-md-3 col-lg-2"
        :access-lists="accessLists"
        :on-access-list-select="onAccessListSelect"
        :on-link-clicked="onMenuLinkClicked"
      ></MainMenu>
      <main class="col-md-9 col-lg-10 ms-auto py-2 text-start">
        <slot></slot>
      </main>
    </div>
  </div>

  <div
    id="error-alert"
    class="alert alert-danger alert-dismissible"
    role="alert"
    v-if="$store.state.errorMessage"
  >
    {{ $store.state.errorMessage }}
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      @click="onErrorClose"
    ></button>
  </div>
</template>

<script>
import api from "../services/api";
import user from "../services/user";
import store from "@/store";
import MainMenu from "@/components/menus/MainMenu";

export default {
  components: { MainMenu },
  emits: ["siteSelected", "accessListSelected", "menuLinkClicked"],
  data() {
    return {
      user: user,
      isMobileMenuVisible: false,
      sites: api.getEmptyCollection(),
      accessLists: api.getEmptyCollection(),
    };
  },
  computed: {
    getSiteId() {
      return store.state.siteId;
    },
  },
  methods: {
    async onLogOut() {
      await api.user.logout();
      await this.$store.dispatch("logout");
      await this.$router.push({ name: "Login" });
    },
    async onSiteSelect(e) {
      await this.$store.dispatch("setSiteId", {
        siteId: parseInt(e.target.value),
      });
      if (this.$route.name === "SiteLeaderSiteSelect") {
        await this.$router.push({ name: "SiteLeaderProductList" });
      }
      this.$emit("siteSelected");
    },
    async onAccessListSelect(id) {
      const accessList = this.accessLists.items.find(
        (item) => item.id === parseInt(id)
      );
      if (accessList) {
        this.$store.dispatch("setAccessList", accessList);
        this.isMobileMenuVisible = false;
        if (this.$route.name !== "SiteLeaderProductList") {
          await this.$router.push({ name: "SiteLeaderProductList" });
          return;
        }
        this.$emit("accessListSelected");
      }
    },
    toggleMenu() {
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
    },
    onMenuLinkClicked() {
      this.isMobileMenuVisible = false;
      this.$emit("menuLinkClicked");
    },
    onErrorClose() {
      this.$store.dispatch("setError", null);
    },
    async fetchData() {
      if (
        this.$store.state.user &&
        this.$store.state.user.role === "ROLE_SITE_LEADER"
      ) {
        this.sites = await api.site.getAll();
        this.accessLists = await api.accessList.getAll();
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/app.scss";

#main-container {
  padding-top: 50px;
}

#navbar-top {
  height: 50px;
  z-index: 10000;
}
.nav-item a {
  white-space: nowrap;
}
.nav-item .badge {
  top: 12px;
}

#desktop-menu {
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 70px;
  padding-bottom: 10px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}

#mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 50px;
  padding-bottom: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background: #fafafa;
  border-top: 1px solid #eee;
  z-index: 9999;
}

#error-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
</style>
