import moment from "moment";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Name",
    key: "name",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Registration code",
    key: "registrationCode",
    hasListView: true,
    formKit: {
      props: {
        type: "text",
        validation: "required",
      },
    },
  },
  {
    name: "Position",
    key: "position",
    hasListView: true,
    formKit: {
      props: {
        type: "radio",
        options: [
          { label: "Contractor", value: "contract" },
          { label: "Board member", value: "board_member" },
        ],
        validation: "required",
      },
    },
    formatter: function (value) {
      if (value == "board_member") {
        return "Board member";
      }
      return "Contractor";
    },
  },
];
