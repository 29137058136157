<template>
  <div class="table-wrapper">
    <table class="table table-hover align-middle">
      <tbody>
        <template v-for="column in columns" :key="column.key">
          <tr v-if="column.viewable !== false">
            <th scope="row">{{ column.name }}</th>
            <td>
              <span v-if="column.formatter">
                <span v-html="column.formatter(data[column.key])"></span>
              </span>
              <span v-else>
                {{ data[column.key] ?? "―" }}
              </span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
    },
    data: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  overflow-x: auto;
}
</style>
