import format from "@/services/format";

export default [
  { name: "ID", key: "id", hasListView: true },
  {
    name: "Site",
    key: "site",
    hasListView: true,
    sortKey: "site.name",
    searchKey: "site.name",
    formatter: format.name,
  },
  {
    name: "Status",
    key: "status",
    hasListView: true,
    formatter: format.orderStatusBadge,
  },
];
